import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'src/i18n';
import 'src/logger';
import { I18nProvider, importMessages } from '@amzn/awsui-components-react/polaris/i18n';
import { AuthProvider } from 'src/providers/AuthProvider';
import { DarkModeProvider } from 'src/providers/DarkModeProvider';
import { MetricsProvider } from 'src/providers/MetricsProvider';
import { NotificationProvider } from 'src/providers/NotificationProvider';
import { QueryProvider } from 'src/providers/QueryProvider';
import { routes } from 'src/routes';
import 'src/index.scss';

const container = document.getElementById('root');
const root = createRoot(container!);

const locale = document.documentElement.lang;
const messages = await importMessages(locale);

root.render(
  <StrictMode>
    <MetricsProvider>
      <I18nProvider locale={locale} messages={messages}>
        <NotificationProvider>
          <QueryProvider>
            <AuthProvider>
              <DarkModeProvider>
                <RouterProvider router={createBrowserRouter(routes)} />
              </DarkModeProvider>
            </AuthProvider>
          </QueryProvider>
        </NotificationProvider>
      </I18nProvider>
    </MetricsProvider>
  </StrictMode>,
);
