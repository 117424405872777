import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, useQueryWithNotifications } from 'src/api/api-utils';
import { IConditionalValidationBusinessRule } from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridRowValidationHelper';
import { useUserAlias } from 'src/hooks/useUserAlias';
import { OTR_PLAN_TYPES, PlanTypeId, RegionId } from 'src/utils/planning/planetModel';

const parseDimensionValueToArray = <T = any>(s?: string): T[] => (s ? JSON.parse(s) : []);

interface IUseBatchDimensionValuesSettings {
  disabled?: boolean;
}

interface IUseBatchBusinessRulesInput {
  datasetName: string;
  planType: PlanTypeId;
  region: RegionId;
}

export const useBatchBusinessRules = (
  input: IUseBatchBusinessRulesInput,
  settings: IUseBatchDimensionValuesSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const getDimensionName = (
    planType: PlanTypeId,
    datasetName: string,
    region: RegionId,
  ): string => {
    if (OTR_PLAN_TYPES.has(planType) || planType === PlanTypeId.FIXED_COST_CONSOLIDATION) {
      return `business_rules#${region}#${datasetName}`;
    }
    return `business_rules#${datasetName}`;
  };

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.DIMENSION_BUSINESS_RULES, input, alias],
    queryFn: async () => {
      const dimensionName: string = getDimensionName(
        input.planType,
        input.datasetName,
        input.region,
      );
      const result = await FoxPlanningApi.getDimensionValues({
        dimensionLevel: input.planType,
        dimensionList: [dimensionName],
        requesterIdentity: alias,
      });
      return parseDimensionValueToArray<IConditionalValidationBusinessRule>(
        result?.dimensions?.[dimensionName],
      );
    },
    meta: { errorNotificationText: t('api_error_fetch_business_rules') },
    enabled: !settings.disabled,
  });
  return query;
};

interface IUseBatchUpsertDatasetsInput {
  planType: PlanTypeId;
}

export const useBatchUpsertDatasets = (
  input: IUseBatchUpsertDatasetsInput,
  settings: IUseBatchDimensionValuesSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.DIMENSION_UPSERT_DATASETS, input, alias],
    queryFn: async () => {
      const dimensionName = 'UpsertDatasets';
      const result = await FoxPlanningApi.getDimensionValues({
        dimensionLevel: input.planType,
        dimensionList: [dimensionName],
        requesterIdentity: alias,
      });
      return parseDimensionValueToArray<string>(result?.dimensions?.[dimensionName]);
    },
    meta: { errorNotificationText: t('api_error_fetch_upsert_datasets') },
    enabled: !settings.disabled,
  });
  return query;
};

interface IUseStaticFilterInput {
  planType: string;
  region: string;
  subGroup: string;
}

export const useStaticFilterList = (
  input: IUseStaticFilterInput,
  settings: IUseBatchDimensionValuesSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.DIMENSION_STATIC_FILTER_LIST, input, alias],
    queryFn: async () => {
      const dimensionName = `staticSiteList#${input.region}#${input.subGroup}`;
      const result = await FoxPlanningApi.getDimensionValues({
        dimensionLevel: input.planType,
        dimensionList: [dimensionName],
        requesterIdentity: alias,
      });
      return parseDimensionValueToArray<string>(result?.dimensions?.[dimensionName]);
    },
    meta: { errorNotificationText: t('api_error_fetch_static_filter_list') },
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: !settings.disabled,
  });
  return query;
};

interface IUsePortfolioIdsInput {
  planType: string;
}

export const usePortfolioIds = (
  input: IUsePortfolioIdsInput,
  settings: IUseBatchDimensionValuesSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.DIMENSION_PORTFOLIO_IDS, input, alias],
    queryFn: async () => {
      const dimensionName = 'portfolioIds';
      const result = await FoxPlanningApi.getDimensionValues({
        dimensionLevel: input.planType,
        dimensionList: [dimensionName],
        requesterIdentity: alias,
      });
      return parseDimensionValueToArray<string>(result?.dimensions?.[dimensionName]);
    },
    meta: { errorNotificationText: t('api_error_fetch_portfolio_ids') },
    enabled: !settings.disabled,
  });

  return query;
};

interface IUseScenariosInput {
  planType: string;
}

export const useScenarios = (
  input: IUseScenariosInput,
  settings: IUseBatchDimensionValuesSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.DIMENSION_SCENARIOS, input, alias],
    queryFn: async () => {
      const dimensionName = 'scenarios';
      const result = await FoxPlanningApi.getDimensionValues({
        dimensionLevel: input.planType,
        dimensionList: [dimensionName],
        requesterIdentity: alias,
      });
      return parseDimensionValueToArray<string>(result?.dimensions?.[dimensionName]);
    },
    meta: { errorNotificationText: t('api_error_fetch_scenarios') },
    enabled: !settings.disabled,
  });

  return query;
};
