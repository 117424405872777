import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import {
  Alert,
  Box,
  Button,
  Modal,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react/polaris';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export type ConfirmModalInfo<T = any> = null | {
  onCancel?: () => void;
  onProceed?: () => void;
  header?: string;
  message?: string;
  table?: {
    items: T[];
    columnDefinitions: TableProps.ColumnDefinition<T>[];
  };
};

export interface UserConfirmModalProps<T = any> {
  confirmModalInfo: ConfirmModalInfo<T>;
  setConfirmModalInfo: Dispatch<SetStateAction<ConfirmModalInfo<T>>>;
}

const UserConfirmModal = ({ confirmModalInfo, setConfirmModalInfo }: UserConfirmModalProps) => {
  const { t } = useTranslation();

  const onCancel = () => {
    confirmModalInfo?.onCancel?.();
    setConfirmModalInfo(null);
  };

  const onProceed = () => {
    confirmModalInfo?.onProceed?.();
    setConfirmModalInfo(null);
  };

  return (
    <Modal
      visible={!!confirmModalInfo}
      onDismiss={onCancel}
      header={confirmModalInfo?.header}
      size={confirmModalInfo?.table ? 'max' : 'medium'}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              {t('cancel')}
            </Button>
            <Button variant="primary" onClick={onProceed}>
              {t('proceed_button')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {/* Alert Message */}
      {confirmModalInfo?.message ? <Alert type="warning">{confirmModalInfo?.message}</Alert> : null}

      {/* Table Data */}
      {confirmModalInfo?.table ? (
        <Box margin={{ top: 's' }}>
          <Table
            items={confirmModalInfo.table.items}
            columnDefinitions={confirmModalInfo.table.columnDefinitions}
            wrapLines={true}
          />
        </Box>
      ) : null}
    </Modal>
  );
};

export default UserConfirmModal;
