import { GetBatchRequest } from '@amzn/fox-den-cost-planning-lambda';
import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, RequestVariables, useQueryWithNotifications } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';
import { parseBatchMetadataFields } from 'src/utils/planning/batchMetadataModel';

type UseBatchRequest = Omit<RequestVariables<GetBatchRequest>, 'batchId'> & {
  batchId: string | undefined;
};

interface IUseBatchMetadataSettings {
  disabled?: boolean;
  refetchInterval?: number;
  staleTime?: number;
}

export const useBatchMetadata = (
  request: UseBatchRequest,
  settings: IUseBatchMetadataSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.BATCH_METADATA, request.batchId, request, alias],
    queryFn: async () => {
      const unparsedBatchMetadata = await FoxPlanningApi.getBatch({
        batchId: request.batchId ?? '',
        requestFieldList: request.requestFieldList,
        batchVersion: '0',
        requesterIdentity: alias,
      });

      return parseBatchMetadataFields(unparsedBatchMetadata);
    },
    meta: { errorNotificationText: t('api_error_fetch_plan_metadata') },
    enabled: !settings.disabled && !!request.batchId,
    refetchInterval: settings.refetchInterval ?? false,
    ...(settings?.staleTime !== undefined ? { staleTime: settings.staleTime } : {}),
  });

  return query;
};

/**
 * Manual Fetch. Don't use unless required
 */
export const fetchBatchMetadata = async (
  getBatchRequest: Omit<GetBatchRequest, 'batchVersion'>,
) => {
  const unparsedBatchMetadata = await FoxPlanningApi.getBatch({
    batchId: getBatchRequest.batchId ?? '',
    requestFieldList: getBatchRequest.requestFieldList,
    batchVersion: '0',
    requesterIdentity: getBatchRequest.requesterIdentity,
  });

  return parseBatchMetadataFields(unparsedBatchMetadata);
};
