import { GetDatasetDataRequest } from '@amzn/fox-den-cost-planning-lambda';
import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, useQueryWithNotifications, RequestVariables } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';

/** adding a counter parameter as a part of queryKey, so we can enforce a data update by changing the number of counter */
export type UseDatasetDataRequest = RequestVariables<GetDatasetDataRequest> & { counter: number };

interface UseDatasetDataSettings {
  disabled?: boolean;
}

export const useDatasetData = (
  request: UseDatasetDataRequest | null,
  settings: UseDatasetDataSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  return useQueryWithNotifications({
    queryKey: [
      QUERY_KEYS.PLAN_SSRM_DATASET_DATA,
      request?.batchId,
      request?.datasetName,
      request,
      alias,
    ],
    queryFn: async () => {
      if (!request?.batchId || !request.datasetName) return null;

      const { counter, ...requestParams } = request;

      const result = await FoxPlanningApi.getDatasetData({
        ...requestParams,
        batchVersion: '0',
        requesterIdentity: alias,
      });

      return result;
    },
    meta: { errorNotificationText: t('api_error_fetch_dataset_data') },
    cacheTime: 0,
    staleTime: Infinity,
    enabled: !settings.disabled,
  });
};
