/**
 * This is deprecated as we are moving to server side pagination for all datasets.
 * After all module have been migrated we will no longer be using this API.
 */

import { FetchDataRequest, SourceLocator } from '@amzn/fox-den-cost-planning-lambda';
import { flatten, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { parse } from 'papaparse';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, useQueryWithNotifications, RequestVariables } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';
import { downloadS3Data, parseS3UriToLocator } from 'src/utils/s3';
import { ITableDataSchemaColumnItem } from 'src/utils/planning/batchMetadataModel';
import { getCellDataTypeFromSchema } from 'src/utils/planning/useDatasetSchema';

interface IUsePlanDatasetSettings {
  disabled?: boolean;
  cacheTime?: number;
  staleTime?: number;
}

export interface IUsePlanDatasetMetadata {
  locations?: string[];
  partitionS3VersionMap?: Record<string, string> | null;
  partitionLocator?: SourceLocator | null;
}

interface IUsePlanDatasetResultData {
  dataset: Record<string, string | number>[];
  metadata: IUsePlanDatasetMetadata;
}

const normalizeEndOfLine = (data: string) => data.replace('\r\n', '\n');

const convertCsvToJsonObject = (
  csvString: string,
  schema: ITableDataSchemaColumnItem[] | null = null,
) => {
  const lines: string[][] = parse(normalizeEndOfLine(csvString), {
    skipEmptyLines: true,
  }).data as string[][];

  const result = [];

  // If header is not passed in, treat the first row as header
  const headers = schema ? schema?.map((item) => item.dimensionName) : lines[0];
  const startIndex = schema ? 0 : 1;

  for (let i = startIndex; i < lines.length; i++) {
    const obj: Record<string, string | number> = {};
    const currentLine = lines[i];

    // Skip the row if it equals to header.
    if (isEqual(headers, currentLine)) continue;

    for (let j = 0; j < headers.length; j++) {
      if (getCellDataTypeFromSchema(schema?.[j]) === 'number') {
        if (currentLine[j] === '') {
          obj[headers[j]] = '';
        } else {
          obj[headers[j]] = parseFloat(currentLine[j]);
        }
      } else {
        obj[headers[j]] = currentLine[j];
      }
    }

    result.push(obj);
  }

  return result;
};

export const usePlanDataset = (
  request: RequestVariables<FetchDataRequest>,
  schema: ITableDataSchemaColumnItem[] | null = null,
  settings: IUsePlanDatasetSettings = {},
) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  const query = useQueryWithNotifications({
    queryKey: [QUERY_KEYS.PLAN_DATASET, request.batchId, request.datasetName, request, alias],
    queryFn: async () => {
      const planDatasetInfo = await FoxPlanningApi.fetchData({
        ...request,
        batchVersion: '0',
        requesterIdentity: alias,
      });

      const { locations, partitionS3VersionMap, partitionLocator } = planDatasetInfo;

      const gridDataChunks = await Promise.all(
        (locations || []).map(async (location) => {
          const locator = parseS3UriToLocator(location);
          const dataChunk = await downloadS3Data(locator, partitionLocator);
          return convertCsvToJsonObject(dataChunk, schema);
        }),
      );

      const gridData = flatten(gridDataChunks);

      const result: IUsePlanDatasetResultData = {
        dataset: gridData,
        metadata: {
          locations: locations ?? undefined,
          partitionS3VersionMap: partitionS3VersionMap ?? undefined,
          partitionLocator: partitionLocator ?? undefined,
        },
      };

      return result;
    },
    meta: { errorNotificationText: t('api_error_fetch_plan_dataset') },
    enabled: !settings.disabled,
    cacheTime: settings.cacheTime,
    staleTime: settings.staleTime,
  });

  return query;
};
