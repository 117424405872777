import {
  ColumnPivotChangedEvent,
  ColumnPivotModeChangedEvent,
  ColumnRowGroupChangedEvent,
  ColumnValueChangedEvent,
  GridReadyEvent,
} from 'ag-grid-community';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { LocalStorageKeys, useLocalStorage } from 'src/hooks/useLocalStorage';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';

interface UseAgGridPivotModeConfigProps {
  setCurrentGridPivotMode: (isPivotMode: boolean) => void;
}

class AgGridPivotModeConfig {
  rowGroupColumns: string[] = [];
  pivotColumns: string[] = [];
  valueColumns: { colId: string; aggFunc: string }[] = [];
}

/** default configuration for each dataset (if applicable) */
const DATASET_DEFAULT_CONFIG_MAP: Partial<Record<SupportedDatasetType, AgGridPivotModeConfig>> = {
  /** we can add entries here to provide default pivot grid options for each dataset */
};

const DEFAULT_AGG_FUNC = 'sum';

export const useAgGridPivotModeConfig = ({
  setCurrentGridPivotMode,
}: UseAgGridPivotModeConfigProps) => {
  const { batchId } = useParams();

  const { queryParams } = useQueryParams<{ dataset: SupportedDatasetType }>();

  const [pivotModeConfig, setPivotModeConfig] = useLocalStorage<AgGridPivotModeConfig>({
    key: [LocalStorageKeys.AG_GRID_PIVOT_CONFIG, batchId!, queryParams.dataset],
    defaultValue: DATASET_DEFAULT_CONFIG_MAP[queryParams.dataset] ?? new AgGridPivotModeConfig(),
  });

  /** restore pivot mode configuration from localStorage */
  const restorePivotModeConfig = useCallback(
    (e: ColumnPivotModeChangedEvent | GridReadyEvent) => {
      if (e.api.isPivotMode()) {
        /** restore rowGroupColumns and pivotColumns */
        e.api.setRowGroupColumns(pivotModeConfig.rowGroupColumns);
        e.api.setPivotColumns(pivotModeConfig.pivotColumns);

        /** restore valueColumns and aggregate functions */
        for (const { colId, aggFunc } of pivotModeConfig.valueColumns) {
          e.api.addValueColumns([colId]);
          e.api.setColumnAggFunc(colId, aggFunc);
        }
      }
    },
    [pivotModeConfig],
  );

  /** for pivot mode, save rowGroupColumns configuration to localStorage */
  const onColumnRowGroupChanged = useCallback(
    (e: ColumnRowGroupChangedEvent) => {
      if (e.api.isPivotMode()) {
        setPivotModeConfig({
          ...pivotModeConfig,
          rowGroupColumns: e.api.getRowGroupColumns().map((o) => o.getColId()),
        });
      }
    },
    [pivotModeConfig, setPivotModeConfig],
  );

  /** for pivot mode, save pivotColumns configuration to localStorage */
  const onColumnPivotChanged = useCallback(
    (e: ColumnPivotChangedEvent) => {
      if (e.api.isPivotMode()) {
        setPivotModeConfig({
          ...pivotModeConfig,
          pivotColumns: e.api.getPivotColumns().map((o) => o.getColId()),
        });
      }
    },
    [pivotModeConfig, setPivotModeConfig],
  );

  /** for pivot mode, save valueColumns and aggregate functions configuration to localStorage */
  const onColumnValueChanged = useCallback(
    (e: ColumnValueChangedEvent) => {
      if (e.api.isPivotMode()) {
        setPivotModeConfig({
          ...pivotModeConfig,
          valueColumns: e.api.getValueColumns().map((o) => ({
            colId: o.getColId(),
            aggFunc: o.getAggFunc() as string,
          })),
        });
      }
    },
    [pivotModeConfig, setPivotModeConfig],
  );

  /** clean up all configured columns & restore configuration for pivot mode */
  const onColumnPivotModeChanged = useCallback(
    (e: ColumnPivotModeChangedEvent) => {
      /** clean up all aggregate functions */
      for (const col of e.api.getValueColumns()) {
        e.api.setColumnAggFunc(col.getColId(), DEFAULT_AGG_FUNC);
      }

      /** clean up all configured columns */
      e.api.setRowGroupColumns([]);
      e.api.setPivotColumns([]);
      e.api.setValueColumns([]);

      /** sync pivot mode status to parent component */
      setCurrentGridPivotMode(e.api.isPivotMode());

      /** restore pivot mode configuration from localStorage */
      restorePivotModeConfig(e);
    },
    [restorePivotModeConfig, setCurrentGridPivotMode],
  );

  return {
    restorePivotModeConfig,
    onColumnRowGroupChanged,
    onColumnPivotChanged,
    onColumnValueChanged,
    onColumnPivotModeChanged,
  };
};
