import { AgGridBaseWrapper } from 'src/common/ag-grid/AgGridBaseWrapper';
import { AG_OUTPUT_PAGINATION_PAGE_SIZE } from 'src/pages/plan-manager-page/plan-output-tab/planOutputConfig';
import { GridComponentProps } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import DatasetGridContainer from 'src/pages/commons/plan-views/DatasetGridContainer';
import { useSsrmGridCommonParams } from 'src/pages/commons/data-grids/useSsrmGridCommonParams';
import { useSsrmOutputGridParams } from 'src/pages/plan-manager-page/plan-output-tab/components/useSsrmOutputGridParams';

const PlanOutputSsrmGrid = ({
  headerText,
  description,
  datasetOptions,
  currentDataset,
  setCurrentDataset,
  setCurrentGridPivotMode,
  currentGridToolPanel,
  setCurrentGridToolPanel,
}: GridComponentProps) => {
  /** ssrm grid common params */
  const {
    gridRef,
    containerRef,
    gridHeight,
    columnDefs,
    datasetData,
    serverSideDatasource,
    rowSelection,
    cellSelection,
    isFullScreen,
    resizeColumns,
    onColumnPivotModeChanged,
    onToolPanelVisibleChanged,
    setIsFullScreen,
    setAgGridRowData,
    getDatasetExportData,
  } = useSsrmGridCommonParams({ currentDataset, setCurrentGridPivotMode, setCurrentGridToolPanel });

  /** plan output grid specific params */
  const { actionButtonGroups, getRowId, handleDatasetSelected } = useSsrmOutputGridParams({
    gridRef,
    datasetData,
    currentDataset,
    setIsFullScreen,
    setAgGridRowData,
    setCurrentDataset,
    getDatasetExportData,
  });

  return (
    <DatasetGridContainer
      currentDataset={currentDataset}
      onDatasetSelected={handleDatasetSelected}
      datasetOptions={datasetOptions}
      headerText={headerText}
      description={description}
      actionButtonGroups={actionButtonGroups}
    >
      <div ref={containerRef}>
        <AgGridBaseWrapper
          /** custom props */
          gridRef={gridRef}
          gridHeight={gridHeight}
          isFullScreen={isFullScreen}
          setFullScreen={setIsFullScreen}
          /** ag grid props */
          rowModelType="serverSide"
          serverSideDatasource={serverSideDatasource}
          columnDefs={columnDefs}
          cellSelection={cellSelection}
          rowSelection={rowSelection}
          gridToolPanel={currentGridToolPanel}
          pagination={true}
          paginationPageSizeSelector={false}
          paginationPageSize={AG_OUTPUT_PAGINATION_PAGE_SIZE}
          suppressRowVirtualisation={true}
          suppressColumnVirtualisation={true}
          cacheBlockSize={250}
          tooltipShowDelay={1000}
          getRowId={getRowId}
          onModelUpdated={resizeColumns}
          onPaginationChanged={resizeColumns}
          onColumnPivotModeChanged={onColumnPivotModeChanged}
          onToolPanelVisibleChanged={onToolPanelVisibleChanged}
        />
      </div>
    </DatasetGridContainer>
  );
};

export default PlanOutputSsrmGrid;
