import { AiOutlineFullscreen } from 'react-icons/ai';
import { BiExport, BiRefresh } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GetDatasetDataResponse } from '@amzn/fox-den-cost-planning-lambda';
import { uniqueId } from 'lodash';
import { QueryObserverResult } from '@tanstack/react-query';
import { DataGridActionButtonGroups } from 'src/pages/commons/plan-views/DatasetGridContainer';
import { useTriggerExtractOutput } from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { SelectChangeEvent } from 'src/common/EventType';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';
import { SetAgGridRowData } from 'src/pages/commons/data-grids/useSsrmGridCommonParams';
import { downloadFile } from 'src/utils/errorMessage';

interface UseSsrmOutputGridParamsProps {
  gridRef: RefObject<AgGridReact>;
  datasetData: GetDatasetDataResponse | null | undefined;
  currentDataset: SupportedDatasetType;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
  setAgGridRowData: SetAgGridRowData | null;
  setCurrentDataset: (currentDataset: SupportedDatasetType) => void;
  getDatasetExportData: () => Promise<QueryObserverResult<GetDatasetDataResponse | null, unknown>>;
}

export const useSsrmOutputGridParams = ({
  gridRef,
  datasetData,
  currentDataset,
  setIsFullScreen,
  setAgGridRowData,
  setCurrentDataset,
  getDatasetExportData,
}: UseSsrmOutputGridParamsProps) => {
  const { t } = useTranslation();
  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { triggerExtractOutput, isExtractOutputSupported } = useTriggerExtractOutput(
    batchMetadata?.costType,
  );

  const actionButtonGroups = useMemo(() => {
    const handleClickExport = async () => {
      const { data: datasetExportData } = await getDatasetExportData();
      downloadFile(datasetExportData?.locations?.[0], { fileName: currentDataset });
    };

    const handleClickRefresh = () => {
      if (!batchId) return;
      if (isExtractOutputSupported) triggerExtractOutput({ batchId });
      else gridRef.current?.api.refreshServerSide({ purge: true });
    };

    const handleClickExpand = () => {
      setIsFullScreen(true);
    };

    const isExceedExportLimit = datasetData?.totalRowCount && datasetData.totalRowCount >= 999999;

    const buttonGroups: DataGridActionButtonGroups = [
      [
        {
          icon: BiExport,
          text: t('export'),
          onClick: handleClickExport,
          disabled: isExceedExportLimit ? t('dataset_size_exceed_export_limit') : false,
        },
        { icon: BiRefresh, text: t('refresh'), onClick: handleClickRefresh },
      ],
      [{ icon: AiOutlineFullscreen, text: t('expand'), onClick: handleClickExpand }],
    ];

    return buttonGroups;
  }, [
    batchId,
    currentDataset,
    datasetData?.totalRowCount,
    getDatasetExportData,
    gridRef,
    isExtractOutputSupported,
    setIsFullScreen,
    t,
    triggerExtractOutput,
  ]);

  const getRowId = useCallback(() => uniqueId(), []);

  const handleDatasetSelected = (e: SelectChangeEvent) => {
    const newDataset = e.detail.selectedOption.value as SupportedDatasetType;
    if (newDataset !== currentDataset) setCurrentDataset(newDataset);
  };

  /** after fetching the dataset from server, set it to ag grid */
  useEffect(() => {
    if (!datasetData) return;

    setAgGridRowData?.({
      rowData: datasetData.rowData || [],
      rowCount: datasetData.totalRowCount || undefined,
    });
  }, [datasetData, setAgGridRowData]);

  return { actionButtonGroups, getRowId, handleDatasetSelected };
};
