import { Box, Spinner, Tabs } from '@amzn/awsui-components-react/polaris';
import { TabsProps } from '@amzn/awsui-components-react/polaris/tabs';
import { TFunction } from 'i18next';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { TabsChangeEvent } from 'src/common/EventType';
import { useQueryParams } from 'src/hooks/useQueryParams';
import {
  DatasetInfo,
  PlanInputSubTab,
  SubTabInfo,
} from 'src/pages/plan-manager-page/plan-input-tab/planInputConfig';
import { PlanOutputSubTab } from 'src/pages/plan-manager-page/plan-output-tab/planOutputConfig';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';

export interface DatasetOption {
  label: string;
  value: SupportedDatasetType;
  disabled: boolean;
}

export type GetSubTabs<T> = (
  batchMetadata: IBatchMetadataParsed | undefined,
  t: TFunction<'translation', undefined>,
) => SubTabInfo<T>[] | null;

export type GetDatasetOptions = (
  batchMetadata: IBatchMetadataParsed | undefined,
  datasets: DatasetInfo[],
) => DatasetOption[];

export interface GridComponentProps {
  headerText: ReactNode;
  description: ReactNode;
  datasetOptions: DatasetOption[];
  currentDataset: SupportedDatasetType;
  setCurrentDataset: (currentDataset: SupportedDatasetType) => void;
  currentGridPivotMode: boolean;
  setCurrentGridPivotMode: (isPivotMode: boolean) => void;
  currentGridToolPanel: string | undefined;
  setCurrentGridToolPanel: (toolPanel: string | undefined) => void;
}

interface DatasetViewerProps<T> {
  getSubTabs: GetSubTabs<T>;
  getDatasetOptions: GetDatasetOptions;
  renderGridComponent: FC<GridComponentProps>;
}

type SupportedSubTab = PlanInputSubTab | PlanOutputSubTab;

type CurrentDatasets = Partial<Record<SupportedSubTab, SupportedDatasetType>>;

enum QueryParams {
  SUB_TAB = 'tabId',
  DATASET = 'dataset',
}

export default function DatasetTabsViewer<T extends SupportedSubTab>({
  getSubTabs,
  getDatasetOptions,
  renderGridComponent,
}: DatasetViewerProps<T>) {
  const { t } = useTranslation();

  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { queryParams, setQueryParams } = useQueryParams();

  const querySubTabId = queryParams[QueryParams.SUB_TAB] ?? '';
  const queryDataset = queryParams[QueryParams.DATASET] ?? '';

  const [isQueryParamsValid, setIsQueryParamsValid] = useState(false);

  const subTabs = useMemo(() => getSubTabs(batchMetadata, t), [batchMetadata, getSubTabs, t]);

  const [currentDatasets, setCurrentDatasets] = useState<CurrentDatasets | null>(null);
  const [currentGridPivotMode, setCurrentGridPivotMode] = useState<boolean>(false);
  const [currentGridToolPanel, setCurrentGridToolPanel] = useState<string | undefined>(undefined);

  const changeQuerySubTabId = useCallback(
    (subTab: SupportedSubTab) => {
      if (!currentDatasets) return;
      setQueryParams({
        [QueryParams.SUB_TAB]: subTab,
        [QueryParams.DATASET]: currentDatasets[subTab]!,
      });
    },
    [currentDatasets, setQueryParams],
  );

  const changeQueryDataset = useCallback(
    (dataset: SupportedDatasetType) => {
      setQueryParams({
        [QueryParams.SUB_TAB]: querySubTabId,
        [QueryParams.DATASET]: dataset,
      });
      setCurrentDatasets((prev) => ({ ...prev, [querySubTabId]: dataset }));
      setCurrentGridPivotMode(false);
      setCurrentGridToolPanel(undefined);
    },
    [querySubTabId, setQueryParams],
  );

  // Set default currentDatasets
  useEffect(() => {
    if (!currentDatasets && subTabs) {
      const defaultDatasets = subTabs.reduce<CurrentDatasets>((acc, curr) => {
        if (curr.id === querySubTabId) return { ...acc, [curr.id]: queryDataset };
        return { ...acc, [curr.id]: curr.datasets[0].id };
      }, {});
      setCurrentDatasets(defaultDatasets);
    }
  }, [queryDataset, querySubTabId, currentDatasets, subTabs]);

  // Verify if querySubTabId and queryDataset are valid and provide fallback if not
  useEffect(() => {
    /** no subTabs */
    if (!subTabs?.length) return;

    /**
     * we used to convert tabId url params to lower case instead of directly using the SupportedSubTab enum value
     * this leads to additional complexity since we have to call toUpperCase/toLowerCases everywhere
     * and also increases vulnerability when any SupportedSubTab enum value is not set to upper case
     * we've deprecated our old way and now uses the enum value directly, so adding backward compatibility here
     */
    if (querySubTabId !== querySubTabId.toUpperCase()) {
      changeQuerySubTabId(querySubTabId.toUpperCase() as SupportedSubTab);
      return;
    }

    const querySubTab = subTabs.find((o) => o.id === querySubTabId);

    /** invalid querySubTab */
    if (!querySubTab) {
      changeQuerySubTabId(subTabs[0].id);
      return;
    }

    /** invalid queryDataset */
    if (!querySubTab.datasets.find((o) => o.id === queryDataset)) {
      changeQueryDataset(querySubTab.datasets[0].id);
    }

    /** confirmed query params are valid */
    setIsQueryParamsValid(true);
  }, [queryDataset, querySubTabId, changeQueryDataset, changeQuerySubTabId, subTabs]);

  const subTabsProps: TabsProps.Tab[] = useMemo(() => {
    if (!subTabs) return [];

    return subTabs.map((tab) => {
      const datasetOptions = getDatasetOptions(batchMetadata, tab.datasets);

      if (datasetOptions.length === 0 || !currentDatasets?.[tab.id]) {
        return {
          label: tab.label,
          id: tab.id,
          content: <Spinner size="big" />,
        };
      }

      return {
        id: tab.id,
        label: tab.label,
        content: (
          <Box margin={{ top: 's' }}>
            {renderGridComponent({
              headerText: tab.label,
              description: tab.descriptionDiv,
              datasetOptions,
              currentDataset: currentDatasets[tab.id]!,
              setCurrentDataset: changeQueryDataset,
              currentGridPivotMode,
              setCurrentGridPivotMode,
              currentGridToolPanel,
              setCurrentGridToolPanel,
            })}
          </Box>
        ),
      };
    });
  }, [
    subTabs,
    getDatasetOptions,
    batchMetadata,
    currentDatasets,
    renderGridComponent,
    changeQueryDataset,
    currentGridPivotMode,
    currentGridToolPanel,
  ]);

  const handleTabChange = (e: TabsChangeEvent) => {
    changeQuerySubTabId(e.detail.activeTabId as SupportedSubTab);
  };

  if (!batchMetadata || !isQueryParamsValid) return <Spinner size="large" />;

  return subTabsProps.length > 1 ? (
    <Box margin={{ top: 'xxs' }}>
      <Tabs
        disableContentPaddings={true}
        tabs={subTabsProps}
        onChange={handleTabChange}
        activeTabId={querySubTabId}
      />
    </Box>
  ) : (
    <Box margin={{ top: 'm' }}>{subTabsProps[0]?.content}</Box>
  );
}
