import { cloneDeep, isEqual } from 'lodash';
import { IRowValidationRowWithId } from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridRowValidationHelper';

export enum IInlineEditType {
  DELETE = 'DELETE',
  ADD = 'ADD',
  MODIFY = 'MODIFY',
}

export interface IInlineEditRecord {
  previous: string[];
  current: string[];
  editType: IInlineEditType;
  rowId: number;
}

export type IInlineEditsMap = Record<string, IInlineEditRecord[]>;

type IInlineEditsUpdater = (inlineEdits: IInlineEditRecord[]) => IInlineEditRecord[];

export const locateRow = (
  keyedData: Record<string, IRowValidationRowWithId>,
  inlineEdit: IInlineEditRecord,
  validHeaders: string[],
) => {
  if (!validHeaders) return null;

  for (const [key, row] of Object.entries(keyedData)) {
    const found = !inlineEdit.previous.some((value, idx) => {
      try {
        const headerValue = validHeaders[idx];
        const datasourceRowValue = row[headerValue].value;
        return datasourceRowValue !== value;
      } catch {
        return false;
      }
    });

    if (found) {
      return key;
    }
  }

  return null;
};

export const agRowInlineEditsUpdate = (
  newRowData: IRowValidationRowWithId,
  validHeaders: string[] | undefined,
  oldRowData: IRowValidationRowWithId,
): IInlineEditsUpdater => {
  const originalValues: string[] = [];
  const oldCurrValues: string[] = [];
  const newCurrValues: string[] = [];

  (validHeaders || []).forEach((header) => {
    if (header in newRowData) {
      originalValues.push(newRowData[header].original ?? '');
      newCurrValues.push(newRowData[header].value ?? '');
    }
    if (oldRowData && header in oldRowData) {
      oldCurrValues.push(oldRowData[header].value ?? '');
    }
  });

  return (currentInlineEdits: IInlineEditRecord[]) => {
    const newInlineEdits = cloneDeep(currentInlineEdits);
    //Remove edit record if user made a change then reverted it
    if (isEqual(newCurrValues, originalValues) && !originalValues.every((val) => val === null)) {
      const idx = newInlineEdits.findIndex(
        (inlineEdit) => inlineEdit.rowId === newRowData.id.value,
      );
      newInlineEdits.splice(idx, 1);
    } else {
      const idx = newInlineEdits.findIndex(
        (inlineEdit) => inlineEdit.rowId === newRowData.id.value,
      );
      if (idx !== -1) {
        //Update existing edit
        newInlineEdits[idx].current = newCurrValues;
      } else {
        //Add new edit
        newInlineEdits.push({
          previous: originalValues,
          current: newCurrValues,
          editType: IInlineEditType.MODIFY,
          rowId: newRowData.id.value,
        });
      }
    }
    return newInlineEdits; // return new inline edits list with updates applied.
  };
};

export const agRowInlineEditsDelete = (
  oldRowData: IRowValidationRowWithId,
  validHeaders: string[],
): IInlineEditsUpdater => {
  const originalValues: string[] = [];
  const oldCurrValues: string[] = [];
  const newCurrValues: string[] = [];
  validHeaders.forEach((header) => {
    if (header in oldRowData) {
      originalValues.push(oldRowData[header].original ?? '');
      oldCurrValues.push(oldRowData[header].value ?? '');
      newCurrValues.push('');
    }
  });

  return (currentInlineEdits: IInlineEditRecord[]) => {
    const newInlineEdits = cloneDeep(currentInlineEdits);

    //Remove added row if user deleted row they just added
    if (originalValues.every((val) => val === '')) {
      const idx = newInlineEdits.findIndex(
        (inlineEdit) => inlineEdit.rowId === oldRowData.id.value,
      );
      newInlineEdits.splice(idx, 1);
    } else {
      const idx = newInlineEdits.findIndex(
        (inlineEdit) => inlineEdit.rowId === oldRowData.id.value,
      );
      if (idx !== -1) {
        // Update existing edit to a DELETE
        newInlineEdits[idx].current = newCurrValues;
        newInlineEdits[idx].editType = IInlineEditType.DELETE;
      } else {
        newInlineEdits.push({
          previous: originalValues,
          current: newCurrValues,
          editType: IInlineEditType.DELETE,
          rowId: oldRowData.id.value,
        });
      }
    }
    return newInlineEdits; // return new inline edits list with delete edit.
  };
};

export const agRowInlineEditsAdd = (
  newRowData: IRowValidationRowWithId,
  validHeaders: string[],
): IInlineEditsUpdater => {
  const originalValues: string[] = [];
  const newCurrValues: string[] = [];
  validHeaders.forEach((header) => {
    if (header in newRowData) {
      originalValues.push('');
      newCurrValues.push(newRowData[header].value ?? '');
    }
  });

  return (currentInlineEdits: IInlineEditRecord[]) => {
    const newInlineEdits = cloneDeep(currentInlineEdits);

    newInlineEdits.push({
      previous: originalValues,
      current: newCurrValues,
      editType: IInlineEditType.ADD,
      rowId: newRowData.id.value,
    });

    return newInlineEdits;
  };
};
