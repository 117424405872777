/* eslint-disable no-case-declarations */
import { BatchesList } from '@amzn/fox-den-cost-planning-lambda';
import {
  CellValueChangedEvent,
  EditableCallbackParams,
  IRowNode,
  ValueSetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty, get as lodashGet, reduce, uniqBy } from 'lodash';
import { RefObject } from 'react';
import {
  ICustomizedAgGridColumnConfig,
  getCellValue,
} from 'src/common/ag-grid/editable-datagrid/AgGridConfigHelper';
import {
  ICellData,
  IValidationResult,
} from 'src/common/ag-grid/editable-datagrid/AgGridValidators';
import { STATIC_FILTER_LIST } from 'src/pages/commons/plan-filters/StaticFilterList';
import { getBaselineSourceTypes } from 'src/pages/plan-manager-page/plan-baseline-tab/BaselineGrid';
import {
  BaselineColumnIds,
  BaselineColumnNames,
  BaselineDatasourceNames,
} from 'src/pages/plan-manager-page/plan-baseline-tab/BaselineModel';
import {
  generateColumnConfigBase,
  getCellValueForCol,
  resetRowNodeValues,
} from 'src/pages/plan-manager-page/plan-baseline-tab/column-configs/columnConfigBase';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import { PlanTypeId } from 'src/utils/planning/planetModel';
import { currentModifiedAt } from 'src/utils/time';

interface ICellEditorParams {
  node: IRowNode;
}

export interface IBaselinePlanOption {
  id: string;
  name: string;
}

type IRowData = Record<BaselineColumnIds, ICellData | string | string[]>;

// TODO: Commented out columns are excluded from Jan 9th, 2024 delivery.
const UTR_COST_COLUMNS: string[] = [
  BaselineColumnIds.EXECUTING_ORDER,
  BaselineColumnIds.SOURCE_TYPE,
  BaselineColumnIds.BATCH_NAME,
  BaselineColumnIds.BATCH_ID,
  BaselineColumnIds.DATE,
  // BaselineColumnIds.COUNTRY_LIST,
  // BaselineColumnIds.SUB_GROUP_TYPE_LIST,
  // BaselineColumnIds.LOCATION_LIST,
  // BaselineColumnIds.METRIC_LIST,
  // BaselineColumnIds.SOURCE_LOGIC,
  // BaselineColumnIds.SOURCE_PERIOD_LIST,
  // BaselineColumnIds.DESTINATION_PERIOD_LIST,
  BaselineColumnIds.MODIFIED_BY,
  BaselineColumnIds.MODIFIED_AT,
];

// Used for Depreciation and Rent until the modules diverge
export const generateColumnConfigUTRCost = ({
  batchMetadata,
  gridRef,
  sourceTypeToListedPlans,
  fetchBatchDataAndAdd,
  idToBatchMetadataMap,
  alias,
}: {
  batchMetadata: IBatchMetadataParsed | undefined;
  gridRef: RefObject<AgGridReact>;
  sourceTypeToListedPlans: Record<string, BatchesList> | undefined;
  fetchBatchDataAndAdd: (batchId: string) => void;
  idToBatchMetadataMap: Record<string, IBatchMetadataParsed>;
  alias: string;
}) => {
  if (!batchMetadata) return { columnConfigs: {}, visibleColumns: UTR_COST_COLUMNS };

  const dateValidationFn =
    (params: ValueSetterParams) =>
    (newValue: unknown): IValidationResult => {
      if (
        getCellValue(lodashGet(params, ['node', 'data', BaselineColumnIds.SOURCE_TYPE])) ===
          BaselineDatasourceNames.SNAPSHOT_DATE &&
        isEmpty(newValue)
      ) {
        return {
          result: false,
          message: 'Snapshot Date should not be empty.',
        };
      }

      return {
        result: true,
        message: 'Validation Passed',
      };
    };

  const planNameValidationFn =
    (params: ValueSetterParams) =>
    (newValue: unknown): IValidationResult => {
      if (typeof newValue !== 'string') {
        return {
          result: false,
          message: 'Plan name should be a string.',
        };
      }

      if (
        getCellValue(lodashGet(params, ['node', 'data', BaselineColumnIds.SOURCE_TYPE])) !==
          BaselineDatasourceNames.SNAPSHOT_DATE &&
        isEmpty(newValue)
      ) {
        return {
          result: false,
          message: 'Plan name should not be empty.',
        };
      }

      return {
        result: true,
        message: 'Validation Passed',
      };
    };

  const isCellEnabled = (columnId: BaselineColumnIds, rowSourceType: BaselineDatasourceNames) => {
    switch (columnId) {
      case BaselineColumnIds.BATCH_NAME:
        if (rowSourceType === BaselineDatasourceNames.PRODUCTIVITY_OUTPUT) {
          return true;
        }
        return false;
      case BaselineColumnIds.DATE:
        if (rowSourceType === BaselineDatasourceNames.SNAPSHOT_DATE) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  const getColumnDataSource = (
    rowNode: IRowNode,
    columnId: BaselineColumnIds,
  ): IBaselinePlanOption[] => {
    const rowData: IRowData = rowNode.data || {};
    const selectedBatchId: string = getCellValue(rowData[BaselineColumnIds.BATCH_ID]);
    const selectedSourceType: string = getCellValue(rowData[BaselineColumnIds.SOURCE_TYPE]);
    const planSelectedInRow: any = idToBatchMetadataMap[selectedBatchId];

    switch (columnId) {
      case BaselineColumnIds.BATCH_NAME:
        // Plans should be unique by batchName.
        if (!sourceTypeToListedPlans?.[selectedSourceType]) {
          return [];
        }

        return sourceTypeToListedPlans[selectedSourceType]
          .sort((a, b) => (b.lastUpdatedOn ?? '').localeCompare(a?.lastUpdatedOn ?? ''))
          .filter((plan) => plan.costType === PlanTypeId.UTR_PRODUCTIVITY)
          .map(({ batchName }: any) => ({
            id: batchName,
            name: batchName,
          }));

      case BaselineColumnIds.COUNTRY_LIST:
        if (!planSelectedInRow) {
          return [];
        }
        const countries: string[] = planSelectedInRow.countries;
        const countriesDataSource = countries?.map((country) => ({
          name: country,
          id: country,
        }));

        return uniqBy(countriesDataSource, 'id');
      case BaselineColumnIds.SUB_GROUP_TYPE_LIST:
        const subGroupTypesDataSource = STATIC_FILTER_LIST.map((item) => ({
          name: item.building_type,
          id: item.building_type,
        })).sort((a, b) => a.name.localeCompare(b.name));
        return uniqBy(subGroupTypesDataSource, 'id');
      default:
        return [];
    }
  };

  // Baseline has a number of shared "basic" columns, however, UTR may have differing requirements.
  // We thus exclude shared columns that were not defined in UTR_COLUMNS.
  const sharedColumnConfigs = reduce(
    generateColumnConfigBase({
      gridRef,
      alias,
    }),
    (accConfigs: any, value, key) => {
      if (UTR_COST_COLUMNS.includes(key)) {
        accConfigs[key] = value;
      }
      return accConfigs;
    },
    {},
  );

  const columnConfigs: Record<string, ICustomizedAgGridColumnConfig> = {
    ...sharedColumnConfigs,
    [BaselineColumnIds.SOURCE_TYPE]: {
      required: true,
      headerName: BaselineColumnNames.SOURCE_TYPE,
      editor: 'AgTreeSelect',
      editorParams: {
        treeDataSource: getBaselineSourceTypes(batchMetadata?.costType ?? ''),
      },
      onCellValueChanged: (params: CellValueChangedEvent) => {
        // TODO: Commented out columns are excluded from Jan 9th, 2024 delivery.
        resetRowNodeValues(
          params.node,
          [BaselineColumnIds.DATE, BaselineColumnIds.BATCH_NAME, BaselineColumnIds.BATCH_ID],
          [],
        );
      },
    },
    [BaselineColumnIds.BATCH_NAME]: {
      headerName: BaselineColumnNames.BATCH_NAME,
      required: true,
      editor: 'AgTreeSelect',
      editorParams: (params: ICellEditorParams) => {
        const editorParams = {
          treeDataSource: getColumnDataSource(params?.node, BaselineColumnIds.BATCH_NAME),
          largeDropdown: true,
        };
        return editorParams;
      },
      editable: (params: EditableCallbackParams): boolean =>
        getColumnDataSource(params?.node, BaselineColumnIds.BATCH_NAME).length !== 0,
      validationFns: [planNameValidationFn],
      onCellValueChanged: (params: CellValueChangedEvent) => {
        const selectedPlanName: string = getCellValueForCol(params);
        const selectedSourceType: string = getCellValueForCol(
          params,
          BaselineColumnIds.SOURCE_TYPE,
        );
        if (!selectedPlanName) return;

        const selectedPlan: any = sourceTypeToListedPlans?.[selectedSourceType]?.find(
          (plan: any) => plan.batchName === selectedPlanName,
        );
        const selectedBatchId = selectedPlan?.batchId;

        params.node.setDataValue(BaselineColumnIds.BATCH_ID, selectedBatchId);

        fetchBatchDataAndAdd(selectedBatchId);

        // resetRowNodeValues(
        //   params.node,
        //   [],
        //   [BaselineColumnIds.COUNTRY_LIST, BaselineColumnIds.METRIC_GROUP_LIST],
        // );

        params.node.setDataValue(BaselineColumnIds.MODIFIED_BY, alias);
        params.node.setDataValue(BaselineColumnIds.MODIFIED_AT, currentModifiedAt());
      },
    },
    [BaselineColumnIds.DATE]: {
      headerName: BaselineColumnNames.DATE,
      editor: 'DatePicker',
      editable: (params: EditableCallbackParams): boolean =>
        batchMetadata?.costType === PlanTypeId.UTR_COST_HC &&
        isCellEnabled(
          BaselineColumnIds.DATE,
          getCellValueForCol(params, BaselineColumnIds.SOURCE_TYPE),
        ),

      validationFns: [dateValidationFn],
    },
  };

  return { columnConfigs, visibleColumns: UTR_COST_COLUMNS };
};
