import { GetDatasetMetadataRequest } from '@amzn/fox-den-cost-planning-lambda';
import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, useQueryWithNotifications, RequestVariables } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';

export const useDatasetMetadata = (request: RequestVariables<GetDatasetMetadataRequest>) => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  return useQueryWithNotifications({
    queryKey: [
      QUERY_KEYS.PLAN_SSRM_DATASET_METADATA,
      request.batchId,
      request.datasetName,
      request,
      alias,
    ],
    queryFn: () =>
      FoxPlanningApi.getDatasetMetadata({
        ...request,
        batchVersion: '0',
        requesterIdentity: alias,
      }),
    meta: { errorNotificationText: t('api_error_fetch_dataset_metadata') },
    cacheTime: 0,
    staleTime: Infinity,
  });
};
