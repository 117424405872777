import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAsyncUploadEditsMutation } from 'src/api/mutation/useAsyncUploadEditsMutation';
import { useCreatePlanMutation } from 'src/api/mutation/useCreateBatchMutation';
import { SLOT_DESCRIPTION_MAX_LEN } from 'src/constants';
import { getPlanDetailsRoute } from 'src/routes';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import { SKIP_ASYNC_UPLOAD_PLAN_TYPE_SET } from 'src/utils/planning/planetModel';

interface IClonePlanModalParams {
  batchMetadata: IBatchMetadataParsed | undefined;
  visible: boolean;
  setShowModal: (value: SetStateAction<boolean>) => void;
}

const ClonePlanModal = ({ batchMetadata, visible, setShowModal }: IClonePlanModalParams) => {
  const [slotDescriptionText, setSlotDescriptionText] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate: asyncUploadEdits } = useAsyncUploadEditsMutation();

  const { mutate: createPlanMutation } = useCreatePlanMutation({
    meta: {
      inProgressNotificationText: t('api_in_progress_clone_plan'),
      errorNotificationText: t('api_error_clone_plan'),
      successNotificationText: t('api_success_clone_plan'),
    },
    onSuccess: (data) => {
      if (data.batchId && data.costType) {
        if (!SKIP_ASYNC_UPLOAD_PLAN_TYPE_SET.has(data.costType)) {
          asyncUploadEdits({
            batchId: data.batchId,
            datasetNames: [],
          });
        }
      }
    },
  });

  useEffect(() => {
    setSlotDescriptionText('');
  }, [visible]);

  const inputValid = true;

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const handleClone = () => {
    if (!batchMetadata) {
      return;
    }

    createPlanMutation(
      {
        ...(batchMetadata as any),
        slotDescription: slotDescriptionText,
        /** need this as a workaround to let BE know the plan is cloned from FE and won't need a name suffix validation */
        batchNameSuffix: '',
      },
      {
        onSuccess: (data) => {
          if (data.batchId) {
            navigate(getPlanDetailsRoute(data.batchId!));
          }
        },
      },
    );

    closeModal();
  };

  const handleCloneSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (inputValid) {
      handleClone();
    }
  };

  useEffect(() => {
    if (!batchMetadata && visible) {
      closeModal();
    }
  }, [batchMetadata, closeModal, visible]);

  return (
    <Modal
      visible={visible}
      onDismiss={closeModal}
      header={t('clone_plan_header')}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={closeModal}>
              {t('cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={handleClone}
              disabled={!inputValid}
              data-testid="submit"
            >
              {t('clone_button')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Trans t={t} i18nKey="clone_plan_message" values={{ planName: batchMetadata?.batchName }} />

        <Alert type="warning">{t('clone_plan_modal_warning')}</Alert>

        <form onSubmit={handleCloneSubmit}>
          <FormField
            label={t('plan_description_label')}
            description={t('plan_description_description')}
            constraintText={t('plan_description_constraint_text')}
          >
            <ColumnLayout columns={2}>
              <Input
                placeholder={t('plan_description_placeholder')}
                onChange={(event) =>
                  setSlotDescriptionText(event.detail.value.slice(0, SLOT_DESCRIPTION_MAX_LEN))
                }
                value={slotDescriptionText}
                ariaRequired={true}
              />
            </ColumnLayout>
          </FormField>
        </form>
      </SpaceBetween>
    </Modal>
  );
};

export default ClonePlanModal;
