import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback, useRef } from 'react';

export const useAgGridResizeColumns = (
  gridRef: RefObject<AgGridReact>,
  containerRef: RefObject<HTMLDivElement>,
) => {
  const timer = useRef<number | undefined>(undefined);

  const resizeColumns = useCallback(() => {
    clearTimeout(timer.current);

    timer.current = window.setTimeout(() => {
      const gridApi = gridRef.current?.api;

      if (!gridApi) return;

      gridApi.autoSizeAllColumns();

      // Following code is to make last column use up remaining column space if
      // autoSizeAllColumns leaves extra space to the right of the last column
      const gridWidth = containerRef.current?.clientWidth;

      if (!gridWidth) return;

      const columns = gridApi?.getAllGridColumns();
      const lastColumn = columns[columns.length - 1];

      if (!lastColumn) return;

      const lastColumnLeft = lastColumn.getLeft();
      const lastColumnWidth = lastColumn.getActualWidth();

      const gridSpaceLeftToFill = Math.max(0, gridWidth - (lastColumnLeft! + lastColumnWidth + 20));

      if (gridSpaceLeftToFill === 0) return;

      const columnState = gridApi.getColumnState();

      columnState[columnState.length - 1].width =
        columnState[columnState.length - 1].width! + gridSpaceLeftToFill;

      gridApi.applyColumnState({ state: columnState });
    }, 1);
  }, [containerRef, gridRef]);

  return { resizeColumns };
};
