import { PlanTagEnumEnum } from '@amzn/fox-den-cost-planning-lambda';
import { useMemo } from 'react';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

const labelMapToSelectOptions = <T extends string>(labelMap: Record<T, string>) =>
  Object.entries(labelMap).map(([k, v]) => ({ label: v as string, value: k as T }));

export enum PlanTypeId {
  CAPEX = 'CapEx',
  DEPRECIATION = 'Depreciation',
  REAL_ESTATE = 'RealEstate',
  JANITORIAL = 'Janitorial',
  SECURITY = 'Security',
  THREE_P_TRANSPORTATION = 'ThreePTransportation',
  OTR_WAREHOUSE_TRANSFER = 'OTRWarehouseTransfer',
  OTR_DOMESTIC_INBOUND = 'OTRDomesticInbound',
  OTR_FREIGHT_CAPITALIZATION = 'OTRFreightCapitalization',
  OTR_SUPPLY_TYPE = 'OTRSupplyType',
  TOPS_DOWN_FORECAST = 'TopsDownForecast',
  UTR_PRODUCTIVITY = 'PLANetProductivity',
  UTR_COST_HC = 'UTRCostHC',
  FIXED_COST_CONSOLIDATION = 'FixedCostConsolidation',
  CF_CONSOLIDATION = 'CFConsolidation',
}

export const PLAN_TYPE_LABEL_MAP: Record<PlanTypeId, string> = {
  [PlanTypeId.CAPEX]: 'CapEx',
  [PlanTypeId.DEPRECIATION]: 'Depreciation',
  [PlanTypeId.REAL_ESTATE]: 'Real Estate',
  [PlanTypeId.JANITORIAL]: 'Janitorial',
  [PlanTypeId.SECURITY]: 'Security',
  [PlanTypeId.THREE_P_TRANSPORTATION]: '3P Transportation',
  [PlanTypeId.OTR_WAREHOUSE_TRANSFER]: 'OTR Warehouse Transfer',
  [PlanTypeId.OTR_DOMESTIC_INBOUND]: 'OTR Domestic Inbound',
  [PlanTypeId.OTR_FREIGHT_CAPITALIZATION]: 'OTR Freight Capitalization',
  [PlanTypeId.OTR_SUPPLY_TYPE]: 'OTR Supply Type',
  [PlanTypeId.TOPS_DOWN_FORECAST]: 'Tops Down Forecast',
  [PlanTypeId.UTR_PRODUCTIVITY]: 'UTR Productivity',
  [PlanTypeId.UTR_COST_HC]: 'UTR Cost/HC',
  [PlanTypeId.FIXED_COST_CONSOLIDATION]: 'Fixed Consolidation',
  [PlanTypeId.CF_CONSOLIDATION]: 'CF Consolidation',
};

export interface IPlanTypeOption {
  label: string;
  value: PlanTypeId;
}

const ALL_PLAN_TYPES: IPlanTypeOption[] = labelMapToSelectOptions(PLAN_TYPE_LABEL_MAP);

export const useSupportedPlanTypes = () => {
  const { data: featureFlags } = useFeatureFlags();

  const supportedPlanTypes = useMemo(
    () =>
      ALL_PLAN_TYPES.filter(({ value }) => {
        switch (value) {
          case PlanTypeId.JANITORIAL:
            return featureFlags?.fePlanJanitorial;
          case PlanTypeId.OTR_SUPPLY_TYPE:
            return featureFlags?.fePlanOtrSupplyType;
          case PlanTypeId.FIXED_COST_CONSOLIDATION:
            return featureFlags?.fePlanFixedCostConsolidation;
          default:
            return true;
        }
      }),
    [featureFlags],
  );

  const supportedPlanTypeSet = useMemo(
    () => new Set(supportedPlanTypes.map((o) => o.value)),
    [supportedPlanTypes],
  );

  return { supportedPlanTypes, supportedPlanTypeSet };
};

export const OTR_IB_PLAN_TYPES: ReadonlySet<PlanTypeId> = new Set([
  PlanTypeId.OTR_WAREHOUSE_TRANSFER,
  PlanTypeId.OTR_DOMESTIC_INBOUND,
  PlanTypeId.OTR_FREIGHT_CAPITALIZATION,
]);

export const OTR_OB_PLAN_TYPES: ReadonlySet<PlanTypeId> = new Set([PlanTypeId.OTR_SUPPLY_TYPE]);

export const OTR_PLAN_TYPES: ReadonlySet<PlanTypeId> = new Set([
  ...OTR_IB_PLAN_TYPES,
  ...OTR_OB_PLAN_TYPES,
]);

export enum BusinessGroupId {
  GCF = 'GCF',
  ATS = 'ATS',
  GDS = 'GDS',
  GSF = 'GSF',
  WWRE = 'WWRE',
  WWOPS = 'WWOPS',
  GTS = 'GTS',
  InboundTransportation = 'InboundTransportation',
  All = 'All',
}

const BUSINESS_GROUP_LABEL_MAP: Record<BusinessGroupId, string> = {
  [BusinessGroupId.GCF]: 'GCF',
  [BusinessGroupId.ATS]: 'ATS',
  [BusinessGroupId.GDS]: 'GDS',
  [BusinessGroupId.GSF]: 'GSF',
  [BusinessGroupId.WWRE]: 'WWRE',
  [BusinessGroupId.WWOPS]: 'WWOPS',
  [BusinessGroupId.GTS]: 'GTS',
  [BusinessGroupId.InboundTransportation]: 'Inbound Transportation',
  [BusinessGroupId.All]: 'All',
};

export interface IBusinessGroupOption {
  label: string;
  value: BusinessGroupId;
}

export const BUSINESS_GROUPS: IBusinessGroupOption[] =
  labelMapToSelectOptions(BUSINESS_GROUP_LABEL_MAP);

export enum SubGroupId {
  FulfillmentCenter = 'FulfillmentCenter',
  SortCenter = 'SortCenter',
  AMZL = 'AMZL',
  AMXL = 'AMXL',
  RSR = 'RSR',
  SSD = 'SSD',
  DistributionCenter = 'DistributionCenter',
  PhysicalStores = 'PhysicalStores',
  Fleet = 'Fleet',
  Air = 'Air',
  Linehaul = 'Linehaul',
  GSP = 'GSP',
  Robotics = 'Robotics',
  ThreePCarriers = 'ThreePCarriers',
  Transportation = 'Transportation',
  AccessPoint = 'AccessPoint',
  All = 'All',
}

export const SUB_GROUP_LABEL_MAP: Record<SubGroupId, string> = {
  [SubGroupId.FulfillmentCenter]: 'FC',
  [SubGroupId.SortCenter]: 'Sort Center',
  [SubGroupId.AMZL]: 'AMZL',
  [SubGroupId.AMXL]: 'AMXL',
  [SubGroupId.RSR]: 'RSR',
  [SubGroupId.SSD]: 'SSD',
  [SubGroupId.DistributionCenter]: 'Distribution Center',
  [SubGroupId.PhysicalStores]: 'Physical Stores',
  [SubGroupId.Fleet]: 'Fleet',
  [SubGroupId.Air]: 'Air',
  [SubGroupId.Linehaul]: 'Linehaul',
  [SubGroupId.GSP]: 'GSP',
  [SubGroupId.Robotics]: 'Robotics',
  [SubGroupId.ThreePCarriers]: '3P Carriers',
  [SubGroupId.Transportation]: 'Transportation',
  [SubGroupId.AccessPoint]: 'Access Point',
  [SubGroupId.All]: 'All',
};

export interface ISubGroupOption {
  label: string;
  value: SubGroupId;
}

export const SUB_GROUPS: ISubGroupOption[] = labelMapToSelectOptions(SUB_GROUP_LABEL_MAP);

export enum RegionId {
  NA = 'NA',
  EU = 'EU',
  APAC = 'APAC',
  LATAM = 'LATAM',
  MENA = 'MENA',
}

const REGION_LABEL_MAP: Record<RegionId, string> = {
  [RegionId.NA]: 'NA',
  [RegionId.EU]: 'EU',
  [RegionId.APAC]: 'APAC',
  [RegionId.LATAM]: 'LATAM',
  [RegionId.MENA]: 'MENA',
};

export interface IRegionOption {
  label: string;
  value: RegionId;
}

export const REGIONS: IRegionOption[] = labelMapToSelectOptions(REGION_LABEL_MAP);

export enum PlanningCycleId {
  Cycle3YF = '3YF',
  CycleOP1 = 'OP1',
  CycleOP2 = 'OP2',
  CycleQ2G = 'Q2G',
  CycleQ3G = 'Q3G',
  CycleQ4G = 'Q4G',
  CycleTD = 'TD',
  CycleActuals = 'Actuals',
}

export const PLAN_TAG_LABEL_MAP: Record<PlanTagEnumEnum, string> = {
  [PlanTagEnumEnum.Test]: 'Test',
  [PlanTagEnumEnum.PreAlpha]: 'PreAlpha',
  [PlanTagEnumEnum.Alpha]: 'Alpha',
  [PlanTagEnumEnum.Bravo]: 'Bravo',
  [PlanTagEnumEnum.Charlie]: 'Charlie',
  [PlanTagEnumEnum.Delta]: 'Delta',
  [PlanTagEnumEnum.Echo]: 'Echo',
  [PlanTagEnumEnum.Foxtrot]: 'Foxtrot',
  [PlanTagEnumEnum.Golf]: 'Golf',
  [PlanTagEnumEnum.Hotel]: 'Hotel',
  [PlanTagEnumEnum.Indigo]: 'Indigo',
  [PlanTagEnumEnum.Juliet]: 'Juliet',
  [PlanTagEnumEnum.Kilo]: 'Kilo',
  [PlanTagEnumEnum.Lima]: 'Lima',
  [PlanTagEnumEnum.Oscar]: 'Oscar',
  [PlanTagEnumEnum._]: '-',
};

export const PLAN_TAG_OPTIONS = labelMapToSelectOptions(PLAN_TAG_LABEL_MAP);

export const LOCAL_CURRENCY = 'Local Currency';
export const CURRENCY_USD = 'USD';
export const RO_CURRENT_STAGE_LABEL = 'RO - Current Stage';
export const RO_CURRENT_STATE_OPTION = {
  label: RO_CURRENT_STAGE_LABEL,
  value: RO_CURRENT_STAGE_LABEL,
};

export enum BatchStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  ALL = 'ALL',
  DELETED = 'DELETED',
}

export enum SupportedDatasetType {
  // CapEx Planning
  CAPEX_PLANNING_FINAL_OUTPUT = 'capex_stg_phasing_actualize',
  CAPEX_ACCOUNT_MAPPING_ACTUAL = 'capex_in_accnt_mapping_actual',
  CAPEX_ACCOUNT_MAPPING_PLAN = 'capex_in_accnt_mapping_plan',
  CAPEX_PHASING_CURVES = 'capex_dim_phasing_curve',
  CAPEX_ACCRUAL_OFFSET = 'capex_in_accrual_offset',
  CAPEX_INITIATIVES = 'capex_in_initiatives',
  CAPEX_NEW_SITES = 'capex_in_new_sites',
  CAPEX_MANUAL_ADJUSTMENTS = 'capex_in_manual_adjustments',
  CAPEX_MOVING_ASSET = 'capex_in_moving_asset',
  CAPEX_MOVING_ASSET_ACTUALIZE = 'capex_in_moving_asset_actualize',

  // Depreciation
  DEPRECIATION_LOCATIONS = 'depreciation_input_depreciation_locations',
  DEPRECIATION_LEDGER_PERIOD = 'depreciation_input_depreciation_ledger_period',
  DEPRECIATION_MANUAL_ADJUSTMENTS = 'depreciation_input_depreciation_manual_adjustments',
  DEPRECIATION_MANUAL_CAPEX = 'depreciation_input_depreciation_manual_capex',
  DEPRECIATION_OUTPUT_PLAN = 'depreciation_output_depreciation_plan',
  DEPRECIATION_CAR_ID_MAPPING_AMZL = 'depreciation_input_depreciation_car_id_mapping_amzl',

  // Real Estate
  REAL_ESTATE_CPI = 'real_estate_input_real_estate_cpi',
  REAL_ESTATE_CLOSING_SITES = 'real_estate_input_real_estate_closing_sites',
  REAL_ESTATE_LAUNCH_MONTHS = 'real_estate_input_real_estate_launch_months',
  REAL_ESTATE_NA_SA_FILE = 'real_estate_input_real_estate_na_sa_file',
  REAL_ESTATE_EU_SA_FILE = 'real_estate_input_real_estate_eu_sa_file',
  REAL_ESTATE_COLOCATION = 'real_estate_input_real_estate_colocation',
  REAL_ESTATE_OTHER_SITES = 'real_estate_input_real_estate_other_sites',
  REAL_ESTATE_PARKRENT = 'real_estate_input_re_parkrent',
  REAL_ESTATE_MANUAL_ADJ = 'real_estate_input_real_estate_manual_adj',
  REAL_ESTATE_PARAMETER_ADJ = 'real_estate_input_real_estate_parameter_adj',
  REAL_ESTATE_OUTPUT_PLAN = 'real_estate_output_real_estate_plan',

  // Janitorial
  JANITORIAL_INPUT_LOCATIONS_NEWSITES = 'janitorial_input_planet_locations_newsites_janit',
  JANITORIAL_INPUT_LOCATIONS_BUILD_TYP_OVRD = 'janitorial_input_planet_locations_buildtypovrd_janit',
  JANITORIAL_INPUT_SJU_ADJ_MANUAL = 'janitorial_input_sju_adj_manual_janit',
  JANITORIAL_INPUT_SUBCAT_MAP_MANUAL = 'janitorial_input_janit_subcat_map_manual',
  JANITORIAL_INPUT_ADDCOST_MANUAL_NEW = 'janitorial_input_janit_addcost_manual_new',
  JANITORIAL_INPUT_INFLATION_MANUAL = 'janitorial_input_janit_inflation_manual',
  JANITORIAL_INPUT_ADDHOURS_MANUAL = 'janitorial_input_janit_addhours_manual',
  JANITORIAL_INPUT_RATE_MAN_NEW = 'janitorial_input_janit_rate_man_new',
  JANITORIAL_INPUT_HOURS_MAN_NEW = 'janitorial_input_janit_hours_man_new',
  JANITORIAL_OUTPUT_PLAN = 'janitorial_output_planet_janitorial_plan',

  // Security
  SECURITY_INPUT_PLANET_LOCATIONS_NEWSITES = 'security_input_planet_locations_newsites',
  SECURITY_INPUT_PLANET_LOCATIONS_BUILDTYPOVRD = 'security_input_planet_locations_buildtypovrd',
  SECURITY_INPUT_SJU_ADJ_MANUAL_NEW = 'security_input_sju_adj_manual_new',
  SECURITY_INPUT_PLANET_SECURITY_SPECTER_MAN = 'security_input_planet_security_specter_man',
  SECURITY_OUTPUT_PLANET_SECURITY_PLAN = 'security_output_planet_security_plan',

  // 3P Transportation
  THREE_P_TRANSPORTATION_INPUT_3P_CARSERVICE_MAP_WW = 'threeP_transportation_input_3p_carservice_map_ww',
  THREE_P_TRANSPORTATION_INPUT_3P_TSSOP_CARMIX_EU = 'threeP_transportation_input_3p_tssop_carmix_eu',
  THREE_P_TRANSPORTATION_INPUT_3P_VOLUME_WW = 'threeP_transportation_input_3p_volume_ww',
  THREE_P_TRANSPORTATION_INPUT_3P_SERVICE_VOL_SPLIT = 'threeP_transportation_input_3p_service_vol_split',
  THREE_P_TRANSPORTATION_INPUT_3P_UM_COST_WW = 'threeP_transportation_input_3p_um_cost_ww',
  THREE_P_TRANSPORTATION_INPUT_3P_CPS_WW = 'threeP_transportation_input_3p_cps_ww',
  THREE_P_TRANSPORTATION_INPUT_3P_CPS_DRIVER_WW = 'threeP_transportation_input_3p_cps_driver_ww',
  THREE_P_TRANSPORTATION_OUTPUT_PLANET_3P_PLAN_WW = 'threeP_transportation_output_planet_3p_plan_ww',

  // OTR Warehouse Transfer
  PLANET_OTR_WHT_INPUT_VOLUME = 'planet_otr_wht_volume',
  PLANET_OTR_WHT_INPUT_SM_ST_DB_MIX = 'planet_otr_wht_sm_st_db_mix',
  PLANET_OTR_WHT_INPUT_UNIT_SIZE = 'planet_otr_wht_unit_size',
  PLANET_OTR_WHT_INPUT_FILL_RATE = 'planet_otr_wht_fill_rate',
  PLANET_OTR_WHT_INPUT_EU_SM_ST_MIX = 'planet_otr_wht_eu_sm_st_mix',
  PLANET_OTR_WHT_INPUT_LENGTH_OF_HAUL = 'planet_otr_wht_length_of_haul',
  PLANET_OTR_WHT_INPUT_RATES = 'planet_otr_wht_rates',
  PLANET_OTR_WHT_INPUT_OVERLAY_COST = 'planet_otr_wht_overlay_cost',
  PLANET_OTR_WHT_OUTPUT_MAIN_COST = 'planet_otr_wht_main_cost',

  // OTR Domestic Inbound
  PLANET_OTR_DIB_INPUT_VOLUME = 'planet_otr_dib_volume',
  PLANET_OTR_DIB_INPUT_SM_TYPE_MIX = 'planet_otr_dib_sm_type_mix',
  PLANET_OTR_DIB_INPUT_FILL_RATE = 'planet_otr_dib_fill_rate',
  PLANET_OTR_DIB_INPUT_PALLETS_PER_ARN = 'planet_otr_dib_pallets_per_arn',
  PLANET_OTR_DIB_INPUT_LBS_PER_SHIPMENT = 'planet_otr_dib_lbs_per_shipment',
  PLANET_OTR_DIB_INPUT_UNIT_SIZE = 'planet_otr_dib_unit_size',
  PLANET_OTR_DIB_INPUT_LENGTH_OF_HAUL = 'planet_otr_dib_length_of_haul',
  PLANET_OTR_DIB_INPUT_RATES = 'planet_otr_dib_rates',
  PLANET_OTR_DIB_INPUT_OVERLAY_COST = 'planet_otr_dib_overlay_cost',
  PLANET_OTR_DIB_INPUT_COST_OVERRIDE = 'planet_otr_dib_cost_override',
  PLANET_OTR_DIB_OUTPUT = 'planet_otr_dib_output',

  // OTR Freight Cap
  PLANET_OTR_FC_INPUT_MANUAL_ADJUSTMENTS = 'planet_otr_fc_manual_adj',
  PLANET_OTR_FC_INPUT_TRANS_EXPENSE = 'planet_otr_fc_trans_expense',
  PLANET_OTR_FC_OUTPUT_WW = 'planet_otr_fc_output_ww',

  // OTR Supply Type
  PLANET_OTR_OB_BLOCK_HOUR_MAPPING = 'planet_otr_ob_block_hour_mapping',
  PLANET_OTR_OB_CANCELLATION_CPL_MAPPING = 'planet_otr_ob_cancellation_cpl_mapping',
  PLANET_OTR_OB_CANCELLATION_RATE = 'planet_otr_ob_cancellation_rate',
  PLANET_OTR_OB_EU_LE_MAPPING = 'planet_otr_ob_eu_le_mapping',
  PLANET_OTR_OB_FIXED_BASE_CPBH = 'planet_otr_ob_fixed_base_cpbh',
  PLANET_OTR_OB_FUEL = 'planet_otr_ob_fuel',
  PLANET_OTR_OB_FUEL_CONSUMPTION_MAPPING = 'planet_otr_ob_fuel_consumption_mapping',
  PLANET_OTR_OB_LDT_OVERRIDE = 'planet_otr_ob_ldt_override',
  PLANET_OTR_OB_NETWORK_LDT_FORECAST = 'planet_otr_ob_network_ldt_forecast',
  PLANET_OTR_OB_NON_AM_BASE_COST = 'planet_otr_ob_non_am_base_cost',
  PLANET_OTR_OB_PRELIM_LOADS = 'planet_otr_ob_prelim_loads',
  PLANET_OTR_OB_SUPPLY_STRATEGY_ATTAINMENT = 'planet_otr_ob_supply_strategy_attainment',
  PLANET_OTR_OB_SUPPLY_TYPE_OUTPUT_ADJ = 'planet_otr_ob_supply_type_output_adj',
  PLANET_OTR_OB_SURFACE_TRANS_OUTPUT_ADJ = 'planet_otr_ob_surface_trans_output_adj',
  PLANET_OTR_OB_TPA = 'planet_otr_ob_tpa',
  PLANET_OTR_OB_HISTORICAL_LOADS = 'planet_otr_ob_historical_loads',
  PLANET_OTR_OB_LDT_ACTUALS = 'planet_otr_ob_ldt_actuals',
  PLANET_OTR_OB_SPEED_ACTUALS = 'planet_otr_ob_speed_actuals',
  PLANET_OTR_OB_CORRIDOR_ALLOC = 'planet_otr_ob_corridor_alloc',
  PLANET_OTR_OB_VIEW_LOADS_BY_DISTANCE_BAND = 'v_planet_otr_ob_loads_by_distance_band',
  PLANET_OTR_OB_VIEW_SUPPLY_STRATEGY_ABS_LOADS = 'v_planet_otr_ob_supply_strategy_abs_loads',
  PLANET_OTR_OB_OUTPUT_LOADS_TRANSFORMED = 'planet_otr_ob_loads_transformed',

  // Tops Down Forecast
  TOPS_DOWN_FORECAST_KPI_BASELINE = 'tops_down_forecast_kpi_baseline',
  TOPS_DOWN_FORECAST_COEFFICIENT = 'tops_down_forecast_coefficient',
  TOPS_DOWN_FORECAST_CHC = 'tops_down_forecast_chc',
  TOPS_DOWN_FORECAST_Q2G_CPU = 'tops_down_forecast_q2g_cpu',
  TOPS_DOWN_FORECAST_VOLUME = 'tops_down_forecast_volume',
  TOPS_DOWN_FORECAST_MANUAL_BRIDGE = 'tops_down_forecast_manual_bridge',
  TOPS_DOWN_FORECAST_CPU_FORECAST = 'tops_down_forecast_cpu_forecast',
  TOPS_DOWN_FORECAST_MM_FORECAST = 'tops_down_forecast_mm_forecast',
  TOPS_DOWN_FORECAST_LUNA_OUTPUT = 'tops_down_forecast_luna_output',

  // PLANet Productivity Planning
  UTR_PRODUCTIVITY_VARIABLE_PLANNED_HIRES_RELEASES_OVERRIDE = 'planet_utr_variable_productivity_planned_hires_releases_override',
  UTR_PRODUCTIVITY_INPUT_OVERLAYS = 'planet_utr_variable_productivity_overlays',
  UTR_PRODUCTIVITY_INPUT_HOURS_ASSUMPTIONS = 'planet_utr_variable_productivity_hours_assumptions',
  UTR_PRODUCTIVITY_INPUT_LEARNING_CURVES = 'planet_utr_variable_productivity_learning_curves',
  UTR_PRODUCTIVITY_INPUT_BASELINE_RATES = 'planet_utr_variable_productivity_baseline_rates',
  UTR_PRODUCTIVITY_INPUT_VOLUME_OVERRIDE = 'planet_utr_variable_productivity_volume_override',
  UTR_PRODUCTIVITY_INPUT_STARTING_HEADCOUNT_OVERRIDE = 'planet_utr_variable_productivity_starting_headcount_override',
  UTR_PRODUCTIVITY_INPUT_HEADCOUNT_ADJUSTMENTS_GUARDRAILS = 'planet_utr_variable_productivity_headcount_adjustments_guardrails',
  UTR_PRODUCTIVITY_INPUT_RULE_BASED_HOURS = 'planet_utr_variable_productivity_rule_based_hours',
  UTR_PRODUCTIVITY_OUTPUT_PLAN = 'planet_utr_variable_productivity_output',

  // UTR Cost HC Planning
  // Large
  PLANET_UTR_VARIABLE_BASE_CPH = 'planet_utr_variable_base_cph',
  PLANET_UTR_VARIABLE_SURCHARGES = 'planet_utr_variable_surcharges',
  PLANET_UTR_VARIABLE_NON_HR_COST = 'planet_utr_variable_non_hr_cost',
  PLANET_UTR_VARIABLE_LEAVE_OF_ABSENCE_PERCENTAGE = 'planet_utr_variable_leave_of_absence_percentage',
  // Small
  PLANET_UTR_VAR_COST_COGNOS_MAPP = 'planet_utr_var_cost_cognos_mapp',
  PLANET_UTR_VARIABLE_HC_EMPSHARE_OVRD = 'planet_utr_variable_hc_empshare_ovrd',
  PLANET_UTR_VARIABLE_BASE_RATE_INCREASE = 'planet_utr_variable_base_rate_increase',
  PLANET_UTR_COST_ROSTERSHARE_OVRD = 'planet_utr_cost_rostershare_ovrd',
  PLANET_UTR_COST_TNASHARE_OVRD = 'planet_utr_cost_tnashare_ovrd',
  PLANET_UTR_COST_TNA_MAN = 'planet_utr_cost_tna_man',
  PLANET_UTR_COST_INFLATION = 'planet_utr_cost_inflation',
  PLANET_UTR_EMPCLASS_MAP = 'planet_utr_empclass_map',
  PLANET_UTR_COST_PAYCODE_MAP = 'planet_utr_cost_paycode_map',
  PLANET_UTR_VARIABLE_COST_HOLIDAY = 'planet_utr_variable_cost_holiday',
  PLANET_UTR_VARIABLE_WEEKEND_PREMIUM_FLAG = 'planet_utr_variable_weekend_premium_flag',
  PLANET_UTR_COST_HC_OUTPUT_PLAN = 'planet_utr_final_cost_output',

  // PLANet Fixed Cost Consolidation
  PLANET_FIXED_COST_CONSOLIDATION_INPUT = 'fixed_cost_consolidation_input',
  PLANET_FIXED_COST_CONSOLIDATION_NON_DESIGNATED_INPUT = 'fixed_cost_consolidation_non_designated_input',
  PLANET_FIXED_COST_CONSOLIDATION_OUTPUT = 'fixed_cost_consolidation_output',

  CF_CONSOLIDATION_INPUT_FC_FIXED = 'cf_consolidation_input_fc_fixed',
  CF_CONSOLIDATION_INPUT_FC_VARIABLE = 'cf_consolidation_input_fc_variable',
  CF_CONSOLIDATION_INPUT_SHIPPED_UNITS = 'cf_consolidation_input_shipped_units',
  CF_CONSOLIDATION_COMBINED_OUTPUT = 'cf_consolidation_combined_output',
}

export const DATASET_TYPE_LABEL_MAP: Record<SupportedDatasetType, string> = {
  [SupportedDatasetType.CAPEX_ACCOUNT_MAPPING_ACTUAL]: 'Account Mapping (Actual)',
  [SupportedDatasetType.CAPEX_ACCOUNT_MAPPING_PLAN]: 'Account Mapping (Plan)',
  [SupportedDatasetType.CAPEX_PHASING_CURVES]: 'Phasing Curves',
  [SupportedDatasetType.CAPEX_ACCRUAL_OFFSET]: 'Accrual Offset',
  [SupportedDatasetType.CAPEX_INITIATIVES]: 'Initiative Inputs',
  [SupportedDatasetType.CAPEX_NEW_SITES]: 'New Sites',
  [SupportedDatasetType.CAPEX_MOVING_ASSET]: 'Moving Asset',
  [SupportedDatasetType.CAPEX_MOVING_ASSET_ACTUALIZE]: 'Moving Asset Actualize',
  [SupportedDatasetType.CAPEX_MANUAL_ADJUSTMENTS]: 'Manual Adjustments',
  [SupportedDatasetType.DEPRECIATION_LOCATIONS]: 'Locations',
  [SupportedDatasetType.DEPRECIATION_LEDGER_PERIOD]: 'Ledger Period',
  [SupportedDatasetType.DEPRECIATION_CAR_ID_MAPPING_AMZL]: 'CAR ID Mapping AMZL',
  [SupportedDatasetType.DEPRECIATION_MANUAL_ADJUSTMENTS]: 'Manual Adjustments',
  [SupportedDatasetType.DEPRECIATION_MANUAL_CAPEX]: 'Manual Capex Overrides',
  [SupportedDatasetType.REAL_ESTATE_CPI]: 'CPI',
  [SupportedDatasetType.REAL_ESTATE_CLOSING_SITES]: 'Closing Sites',
  [SupportedDatasetType.REAL_ESTATE_LAUNCH_MONTHS]: 'Launch Months',
  [SupportedDatasetType.REAL_ESTATE_NA_SA_FILE]: 'NA Station Attributes',
  [SupportedDatasetType.REAL_ESTATE_EU_SA_FILE]: 'EU Station Attributes',
  [SupportedDatasetType.REAL_ESTATE_COLOCATION]: 'Colocation',
  [SupportedDatasetType.REAL_ESTATE_OTHER_SITES]: 'Other Sites',
  [SupportedDatasetType.REAL_ESTATE_PARKRENT]: 'Parking Rent',
  [SupportedDatasetType.REAL_ESTATE_MANUAL_ADJ]: 'Manual Adjustments',
  [SupportedDatasetType.REAL_ESTATE_PARAMETER_ADJ]: 'Parameter Adjustment',
  [SupportedDatasetType.JANITORIAL_INPUT_LOCATIONS_NEWSITES]: 'Location Attributes - New Sites',
  [SupportedDatasetType.JANITORIAL_INPUT_LOCATIONS_BUILD_TYP_OVRD]:
    'Location Attributes - Building-type Correction',
  [SupportedDatasetType.JANITORIAL_INPUT_SJU_ADJ_MANUAL]: 'SJU Adjustment',
  [SupportedDatasetType.JANITORIAL_INPUT_SUBCAT_MAP_MANUAL]: 'Subcategory - Project Mapping',
  [SupportedDatasetType.JANITORIAL_INPUT_ADDCOST_MANUAL_NEW]: 'Additional Costs',
  [SupportedDatasetType.JANITORIAL_INPUT_INFLATION_MANUAL]: 'Wage Rate Inflation',
  [SupportedDatasetType.JANITORIAL_INPUT_ADDHOURS_MANUAL]: 'Additional Hours',
  [SupportedDatasetType.JANITORIAL_INPUT_RATE_MAN_NEW]: 'Janitorial Rates/Fixed Cost',
  [SupportedDatasetType.JANITORIAL_INPUT_HOURS_MAN_NEW]: 'Janitorial Hours',
  [SupportedDatasetType.SECURITY_INPUT_PLANET_LOCATIONS_NEWSITES]:
    'Location Attributes - New Sites',
  [SupportedDatasetType.SECURITY_INPUT_PLANET_LOCATIONS_BUILDTYPOVRD]:
    'Location Attributes - Building-type Correction',
  [SupportedDatasetType.SECURITY_INPUT_PLANET_SECURITY_SPECTER_MAN]: 'Security Rates and Hours',
  [SupportedDatasetType.SECURITY_INPUT_SJU_ADJ_MANUAL_NEW]: 'SJU Adjustment',
  [SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_CARSERVICE_MAP_WW]: 'SM Mapping',
  [SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_TSSOP_CARMIX_EU]: 'Carrier Mix',
  [SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_VOLUME_WW]: 'Volume Input',
  [SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_SERVICE_VOL_SPLIT]:
    'Volume Service Share Override',
  [SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_UM_COST_WW]: 'EU NA Hedges',
  [SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_CPS_WW]: 'Rate Card',
  [SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_CPS_DRIVER_WW]: 'Rate Card Driver',
  [SupportedDatasetType.PLANET_OTR_WHT_INPUT_VOLUME]: 'Volume',
  [SupportedDatasetType.PLANET_OTR_WHT_INPUT_SM_ST_DB_MIX]: 'Volume Distance Band Mix',
  [SupportedDatasetType.PLANET_OTR_WHT_INPUT_UNIT_SIZE]: 'Unit Size',
  [SupportedDatasetType.PLANET_OTR_WHT_INPUT_FILL_RATE]: 'Fill Rate',
  [SupportedDatasetType.PLANET_OTR_WHT_INPUT_EU_SM_ST_MIX]: 'EU Loads Ship Mode Supply Type Mix',
  [SupportedDatasetType.PLANET_OTR_WHT_INPUT_LENGTH_OF_HAUL]: 'Length of Haul',
  [SupportedDatasetType.PLANET_OTR_WHT_INPUT_RATES]: 'Rates',
  [SupportedDatasetType.PLANET_OTR_WHT_INPUT_OVERLAY_COST]: 'Overlay Cost',
  [SupportedDatasetType.PLANET_OTR_DIB_INPUT_VOLUME]: 'DIB Volume',
  [SupportedDatasetType.PLANET_OTR_DIB_INPUT_SM_TYPE_MIX]: 'DIB Volume Mix',
  [SupportedDatasetType.PLANET_OTR_DIB_INPUT_FILL_RATE]: 'DIB Fill Rate',
  [SupportedDatasetType.PLANET_OTR_DIB_INPUT_PALLETS_PER_ARN]: 'DIB Pallets per ARN',
  [SupportedDatasetType.PLANET_OTR_DIB_INPUT_LBS_PER_SHIPMENT]: 'LBs per Shipment',
  [SupportedDatasetType.PLANET_OTR_DIB_INPUT_UNIT_SIZE]: 'DIB Unit Size',
  [SupportedDatasetType.PLANET_OTR_DIB_INPUT_LENGTH_OF_HAUL]: 'DIB Length of Haul',
  [SupportedDatasetType.PLANET_OTR_DIB_INPUT_RATES]: 'DIB Rates',
  [SupportedDatasetType.PLANET_OTR_DIB_INPUT_OVERLAY_COST]: 'DIB Overlay Cost (Optional)',
  [SupportedDatasetType.PLANET_OTR_DIB_INPUT_COST_OVERRIDE]: 'DIB Cost Override (Optional)',
  [SupportedDatasetType.PLANET_OTR_FC_INPUT_TRANS_EXPENSE]:
    'Freight-In Retail Transportation Expense Adjustment',
  [SupportedDatasetType.PLANET_OTR_FC_INPUT_MANUAL_ADJUSTMENTS]: 'Output Manual Adjustment',
  [SupportedDatasetType.PLANET_OTR_OB_BLOCK_HOUR_MAPPING]: 'Block Hour Mapping',
  [SupportedDatasetType.PLANET_OTR_OB_CANCELLATION_CPL_MAPPING]: 'Cancellation Cost per Load',
  [SupportedDatasetType.PLANET_OTR_OB_CANCELLATION_RATE]: 'Cancellation Rate',
  [SupportedDatasetType.PLANET_OTR_OB_EU_LE_MAPPING]: 'EU Loaded Efficiency',
  [SupportedDatasetType.PLANET_OTR_OB_FIXED_BASE_CPBH]: 'Fixed Base CpBH',
  [SupportedDatasetType.PLANET_OTR_OB_FUEL]: 'Fuel Input',
  [SupportedDatasetType.PLANET_OTR_OB_FUEL_CONSUMPTION_MAPPING]: 'Fuel Consumption',
  [SupportedDatasetType.PLANET_OTR_OB_LDT_OVERRIDE]: 'LDT Override (Optional)',
  [SupportedDatasetType.PLANET_OTR_OB_NETWORK_LDT_FORECAST]: 'Network LDT Forecast',
  [SupportedDatasetType.PLANET_OTR_OB_NON_AM_BASE_COST]: 'RLB OW/3P Supply Type Base Cost',
  [SupportedDatasetType.PLANET_OTR_OB_PRELIM_LOADS]: 'Prelim Loads',
  [SupportedDatasetType.PLANET_OTR_OB_SUPPLY_STRATEGY_ATTAINMENT]: 'Supply Strategy Attainment',
  [SupportedDatasetType.PLANET_OTR_OB_SUPPLY_TYPE_OUTPUT_ADJ]:
    'Supply Type Manual Output Adjustment',
  [SupportedDatasetType.PLANET_OTR_OB_SURFACE_TRANS_OUTPUT_ADJ]:
    'Surface Trans Manual Output Adjustment',
  [SupportedDatasetType.PLANET_OTR_OB_TPA]: 'TPA Input',
  [SupportedDatasetType.PLANET_OTR_OB_HISTORICAL_LOADS]: 'Automated - Historical Loads',
  [SupportedDatasetType.PLANET_OTR_OB_LDT_ACTUALS]: 'Automated - LDT Historical Actuals',
  [SupportedDatasetType.PLANET_OTR_OB_SPEED_ACTUALS]: 'Automated - Speed Historical Actuals',
  [SupportedDatasetType.PLANET_OTR_OB_CORRIDOR_ALLOC]: 'Automated - Country to Corridor Allocation',
  [SupportedDatasetType.PLANET_OTR_OB_VIEW_LOADS_BY_DISTANCE_BAND]: 'View with Distance Band',
  [SupportedDatasetType.PLANET_OTR_OB_VIEW_SUPPLY_STRATEGY_ABS_LOADS]:
    'View with Absolute Loads Supply Strategy',
  [SupportedDatasetType.TOPS_DOWN_FORECAST_COEFFICIENT]: 'Coefficient',
  [SupportedDatasetType.TOPS_DOWN_FORECAST_KPI_BASELINE]: 'KPIs',
  [SupportedDatasetType.TOPS_DOWN_FORECAST_CHC]: 'CHC Input',
  [SupportedDatasetType.TOPS_DOWN_FORECAST_Q2G_CPU]: 'Baseline CPU Input',
  [SupportedDatasetType.TOPS_DOWN_FORECAST_VOLUME]: 'Volume Input',
  [SupportedDatasetType.TOPS_DOWN_FORECAST_MANUAL_BRIDGE]: 'Bridge Overlay',
  [SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_OVERLAYS]: 'Productivity Overlays',
  [SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_HOURS_ASSUMPTIONS]: 'Hours Assumptions',
  [SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_BASELINE_RATES]: 'Baseline Rates',
  [SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_RULE_BASED_HOURS]: 'Productivity Rule Based Hours',
  [SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_LEARNING_CURVES]: 'Learning Curves',
  [SupportedDatasetType.UTR_PRODUCTIVITY_VARIABLE_PLANNED_HIRES_RELEASES_OVERRIDE]:
    'Planned Hires Releases Override',
  [SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_VOLUME_OVERRIDE]: 'Volume Override',
  [SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_STARTING_HEADCOUNT_OVERRIDE]:
    'Starting Headcount Override',
  [SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_HEADCOUNT_ADJUSTMENTS_GUARDRAILS]:
    'Headcount Adjustments Guardrails',
  [SupportedDatasetType.PLANET_UTR_VARIABLE_BASE_CPH]: 'Base CPH',
  [SupportedDatasetType.PLANET_UTR_VARIABLE_SURCHARGES]: 'Surcharges',
  [SupportedDatasetType.PLANET_UTR_VARIABLE_NON_HR_COST]: 'Weekly non-hour driven cost',
  [SupportedDatasetType.PLANET_UTR_VARIABLE_LEAVE_OF_ABSENCE_PERCENTAGE]:
    'Leave Of Absence Percentage',
  [SupportedDatasetType.PLANET_UTR_VAR_COST_COGNOS_MAPP]: 'Cost metric Cognos mapping table',
  [SupportedDatasetType.PLANET_UTR_VARIABLE_HC_EMPSHARE_OVRD]: 'Cost - Employee Class Share',
  [SupportedDatasetType.PLANET_UTR_VARIABLE_BASE_RATE_INCREASE]: 'Base CPH increase rate',
  [SupportedDatasetType.PLANET_UTR_COST_ROSTERSHARE_OVRD]: 'HC - Override Employee Mix Ratio',
  [SupportedDatasetType.PLANET_UTR_COST_TNASHARE_OVRD]: 'Override for emp_mix hours share',
  [SupportedDatasetType.PLANET_UTR_COST_TNA_MAN]: 'Time and Attendance Upstream Load',
  [SupportedDatasetType.PLANET_UTR_COST_INFLATION]: 'Cost - Hour driven cost inflation adjustment',
  [SupportedDatasetType.CAPEX_PLANNING_FINAL_OUTPUT]: 'Plan Output',
  [SupportedDatasetType.DEPRECIATION_OUTPUT_PLAN]: 'Plan Output',
  [SupportedDatasetType.REAL_ESTATE_OUTPUT_PLAN]: 'Plan Output',
  [SupportedDatasetType.JANITORIAL_OUTPUT_PLAN]: 'Plan Output',
  [SupportedDatasetType.SECURITY_OUTPUT_PLANET_SECURITY_PLAN]: 'Plan Output',
  [SupportedDatasetType.THREE_P_TRANSPORTATION_OUTPUT_PLANET_3P_PLAN_WW]: 'Output 3P Plan',
  [SupportedDatasetType.PLANET_OTR_WHT_OUTPUT_MAIN_COST]: 'Main Cost Output',
  [SupportedDatasetType.PLANET_OTR_DIB_OUTPUT]: 'Main Cost Output',
  [SupportedDatasetType.PLANET_OTR_FC_OUTPUT_WW]: 'Main Cost Output',
  [SupportedDatasetType.PLANET_OTR_OB_OUTPUT_LOADS_TRANSFORMED]: 'Loads by Business, Distance Band',
  [SupportedDatasetType.TOPS_DOWN_FORECAST_CPU_FORECAST]: 'CPU Forecast',
  [SupportedDatasetType.TOPS_DOWN_FORECAST_MM_FORECAST]: 'MM Forecast',
  [SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT]: 'Luna Output',
  [SupportedDatasetType.UTR_PRODUCTIVITY_OUTPUT_PLAN]: 'Plan Output',
  [SupportedDatasetType.PLANET_UTR_COST_HC_OUTPUT_PLAN]: 'Plan Output',
  [SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_INPUT]: 'Consolidated Input',
  [SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_NON_DESIGNATED_INPUT]:
    'Non-designated Input',
  [SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_OUTPUT]: 'Consolidated output',
  [SupportedDatasetType.CF_CONSOLIDATION_INPUT_FC_FIXED]: 'FC Fixed',
  [SupportedDatasetType.CF_CONSOLIDATION_INPUT_FC_VARIABLE]: 'FC Variable',
  [SupportedDatasetType.CF_CONSOLIDATION_INPUT_SHIPPED_UNITS]: 'Shipped Units',
  [SupportedDatasetType.CF_CONSOLIDATION_COMBINED_OUTPUT]: 'Combined Output',

  // Below datasets are not used in the FE, keeping them here in case we need them in the future
  [SupportedDatasetType.PLANET_UTR_EMPCLASS_MAP]: '',
  [SupportedDatasetType.PLANET_UTR_COST_PAYCODE_MAP]: '',
  [SupportedDatasetType.PLANET_UTR_VARIABLE_COST_HOLIDAY]: '',
  [SupportedDatasetType.PLANET_UTR_VARIABLE_WEEKEND_PREMIUM_FLAG]: '',
};

export enum TriggerType {
  CAPEX_CALL_PROCEDURE = 'CapEx_Call_Procedure',
  CAPEX_EXTRACT_OUTPUT = 'CapEx_Extract_Output',
  DEPRECIATION_CALL_PROCEDURE = 'Depreciation_Call_Procedure',
  DEPRECIATION_EXTRACT_OUTPUT = 'Depreciation_Extract_Output',
  REAL_ESTATE_CALL_PROCEDURE = 'Real_Estate_Call_Procedure',
  REAL_ESTATE_EXTRACT_OUTPUT = 'Real_Estate_Extract_Output',
  JANITORIAL_CALL_PROCEDURE = 'Janitorial_Call_Procedure',
  SECURITY_CALL_PROCEDURE = 'Security_Call_Procedure',
  THREE_P_TRANSPORTATION_CALL_PROCEDURE = 'ThreeP_Transportation_Call_Procedure',
  THREE_P_TRANSPORTATION_EXTRACT_OUTPUT = 'ThreeP_Transportation_Extract_Output',
  OTR_WHT_CALL_PROCEDURE = 'OTR_WHT_Call_Procedure',
  OTR_WHT_EXTRACT_OUTPUT = 'OTR_WHT_Extract_Output',
  OTR_DIB_CALL_PROCEDURE = 'OTR_DIB_Call_Procedure',
  OTR_DIB_EXTRACT_OUTPUT = 'OTR_DIB_Extract_Output',
  OTR_FC_CALL_PROCEDURE = 'OTR_FC_Call_Procedure',
  OTR_FC_EXTRACT_OUTPUT = 'OTR_FC_Extract_Output',
  OTR_OB_CALL_FORECAST_RECONCILIATION = 'OTR_OB_Call_Forecast_Reconciliation',
  OTR_OB_CALL_INTEGRATED_COMPUTATION_LOGIC = 'OTR_OB_Call_Integrated_Computation_Logic',
  OTR_OB_AUTOMATED_DATASET_IMPORT = 'OTR_OB_Automated_Dataset_Import',
  TOPS_DOWN_FORECAST_CREATE_PLAN = 'Tops_Down_Forecast_Create_Plan',
  TOPS_DOWN_FORECAST_BASELINE_IMPORT = 'Tops_Down_Forecast_Baseline_Import',
  TOPS_DOWN_FORECAST_COMPUTE = 'Tops_Down_Forecast_Compute',
  TOPS_DOWN_FORECAST_COMPUTE_FROM_MM = 'Tops_Down_Forecast_Compute_From_MM',
  TOPS_DOWN_FORECAST_COMPUTE_FROM_CPU = 'Tops_Down_Forecast_Compute_From_CPU',
  TOPS_DOWN_FORECAST_LUNA_EXPORT = 'Tops_Down_Forecast_Luna_Export',
  TOPS_DOWN_FORECAST_VOLUME_DATASET_IMPORT = 'Tops_Down_Forecast_Volume_Dataset_Import',
  TOPS_DOWN_FORECAST_BASELINE_CPU_DATASET_IMPORT = 'Tops_Down_Forecast_Baseline_CPU_Dataset_Import',
  FIXED_COST_CONSOLIDATION_CREATE_PLAN = 'Fixed_Cost_Consolidation_Create_Plan',
  FIXED_COST_CONSOLIDATION_COMPUTE = 'Fixed_Cost_Consolidation_Compute',
  UTR_PRODUCTIVITY_CALL_PROCEDURE = 'PLANetProductivity_Call_Procedure',
  UTR_PRODUCTIVITY_EXTRACT_OUTPUT = 'PLANetProductivity_Extract_Output',
  UTR_COST_CALL_PROCEDURE = 'UTRCostHC_Call_Procedure',
  UTR_COST_EXTRACT_OUTPUT = 'UTRCostHC_Extract_Output',
  BASELINE_LOAD = 'PLANet_Baseline_ETL',
  CONSOLIDATION_BASELINE = 'Fixed_Cost_Consolidation_Baseline_Import',
  CF_CONSOLIDATION_COMPUTE = 'CF_Consolidation_Compute',
  CF_CONSOLIDATION_BIFROST_EXPORT = 'CF_Consolidation_Bifrost_Export',
}

export enum ListPlanFilterType {
  UTR_BASELINE = 'UTR_BASELINE',
  R_AND_D_BASELINE = 'R_AND_D_BASELINE',
  CAPEX_FOR_DEPRECIATION_BASELINE = 'CAPEX_FOR_DEPRECIATION_BASELINE',
  FIXED_COST_CONSOLIDATION_BASELINE = 'FIXED_COST_CONSOLIDATION_BASELINE',
}

export enum PeriodTypeId {
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}

export interface IPeriodTypeOption {
  label: PeriodTypeId;
  value: PeriodTypeId;
}

export const SKIP_ASYNC_UPLOAD_PLAN_TYPE_SET: ReadonlySet<string> = new Set([
  PlanTypeId.TOPS_DOWN_FORECAST,
  PlanTypeId.FIXED_COST_CONSOLIDATION,
  PlanTypeId.CF_CONSOLIDATION,
]);
