import { useParams } from 'react-router-dom';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import DatasetTabsViewer from 'src/pages/commons/plan-views/DatasetTabsViewer';
import PlanInputDataGrid from 'src/pages/plan-manager-page/plan-input-tab/components/PlanInputDataGrid';
import PlanOutputDataGrid from 'src/pages/plan-manager-page/plan-output-tab/components/PlanOutputDataGrid';
import PlanOutputSsrmGrid from 'src/pages/plan-manager-page/plan-output-tab/components/PlanOutputSsrmGrid';
import {
  getOutputSubTabs,
  getOutputDatasetOptions,
} from 'src/pages/plan-manager-page/plan-output-tab/planOutputConfig';
import { PlanTypeId, SupportedDatasetType } from 'src/utils/planning/planetModel';

export const TOPS_DOWN_EDITABLE_OUTPUT_DATASET_TYPES = new Set([
  SupportedDatasetType.TOPS_DOWN_FORECAST_CPU_FORECAST,
  SupportedDatasetType.TOPS_DOWN_FORECAST_MM_FORECAST,
  SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT,
]);

export const FIXED_COST_CONSOLIDATION_EDITABLE_OUTPUT_DATASET_TYPES = new Set([
  SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_OUTPUT,
]);

const EDITABLE_OUTPUT_DATASET_TYPES = new Set([
  ...TOPS_DOWN_EDITABLE_OUTPUT_DATASET_TYPES,
  ...FIXED_COST_CONSOLIDATION_EDITABLE_OUTPUT_DATASET_TYPES,
]);

const PlanOutputTab = () => {
  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({ batchId });
  const { data: featureFlags } = useFeatureFlags();

  return (
    <DatasetTabsViewer
      getSubTabs={getOutputSubTabs}
      getDatasetOptions={getOutputDatasetOptions}
      renderGridComponent={(o) => {
        if (
          featureFlags?.feServerSidePaginationUtrProductivityOutput &&
          batchMetadata?.costType === PlanTypeId.UTR_PRODUCTIVITY &&
          !o.currentGridPivotMode
        ) {
          return <PlanOutputSsrmGrid {...o} key={o.currentDataset} />;
        }

        if (EDITABLE_OUTPUT_DATASET_TYPES.has(o.currentDataset)) {
          return <PlanInputDataGrid {...o} key={o.currentDataset} />;
        }

        return <PlanOutputDataGrid {...o} key={o.currentDataset} />;
      }}
    />
  );
};

export default PlanOutputTab;
