import { AgGridReact } from 'ag-grid-react';
import { RefObject } from 'react';
import { IRowValidationRowWithId } from 'src/pages/plan-manager-page/plan-input-tab/ag-grid-utils/inputAgGridRowValidationHelper';
import { AG_INPUT_PAGINATION_PAGE_SIZE } from 'src/pages/plan-manager-page/plan-input-tab/planInputConfig';

/**
 *
 * @param rows A row item
 * @returns A boolean to show if this row has any errors
 * @description Returns true if any cell in the row has an error
 */
export const isErrorRow = (row: IRowValidationRowWithId): boolean => {
  for (const cell of Object.values(row)) {
    if (!cell.lastValidationSuccess) return true;
  }
  return false;
};

/**
 *
 * @param rows A list of rows
 * @returns A list of row ids which have errors
 * @description Returns a set of row ids which have errors
 */
export const getErrorRowIds = (rows: IRowValidationRowWithId[]): Set<number> => {
  const result = new Set<number>();

  for (const row of rows) {
    if (isErrorRow(row)) result.add(row.id.value);
  }

  return result;
};

/**
 *
 * @param gridRef A reference to the ag grid
 * @param errorRowIds A list of row ids which have errors
 * @description Navigate to the next error row in the grid
 */
export const navigateToNextErrorRowIds = (
  gridRef: RefObject<AgGridReact>,
  errorRowIds: Set<number>,
): void => {
  if (errorRowIds.size === 0) return;

  /** get all rows with current sequence */
  const rows: IRowValidationRowWithId[] = [];
  gridRef.current?.api.forEachNodeAfterFilterAndSort((node) => rows.push(node.data));

  /** find the first error row in the sequence  */
  for (let i = 0; i < rows.length; i++) {
    if (errorRowIds.has(rows[i].id.value)) {
      const pageIndex = Math.floor(i / AG_INPUT_PAGINATION_PAGE_SIZE);

      /** navigate to the error row */
      gridRef.current?.api.paginationGoToPage(pageIndex);
      gridRef.current?.api.ensureIndexVisible(i);

      break;
    }
  }
};
