import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AscDescEnum } from '@amzn/fox-den-cost-planning-lambda';
import {
  CellSelectionOptions,
  ColumnPivotModeChangedEvent,
  IServerSideDatasource,
  LoadSuccessParams,
  RowSelectionOptions,
  ToolPanelVisibleChangedEvent,
} from 'ag-grid-community';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';
import { usePlanPageContext } from 'src/pages/plan-manager-page/PlanManagerPage';
import { useAgGridResizeColumns } from 'src/common/ag-grid/useAgGridResizeColumns';
import { useAgGridCalculatedHeight } from 'src/common/ag-grid/useAgGridCalculatedHeight';
import { useDatasetData, UseDatasetDataRequest } from 'src/api/query/useDatasetData';
import { useDatasetMetadata } from 'src/api/query/useDatasetMetadata';
import { useDatasetSchema } from 'src/utils/planning/useDatasetSchema';

interface UseSsrmCommonGridParamsProps {
  currentDataset: SupportedDatasetType;
  setCurrentGridPivotMode: (isPivotMode: boolean) => void;
  setCurrentGridToolPanel: (toolPanel: string | undefined) => void;
}

export type SetAgGridRowData = (params: LoadSuccessParams) => void;

export const useSsrmGridCommonParams = ({
  currentDataset,
  setCurrentGridPivotMode,
  setCurrentGridToolPanel,
}: UseSsrmCommonGridParamsProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({ batchId });
  const { ssrmStaleDatasets, setSsrmStaleDatasets } = usePlanPageContext();

  const { gridHeight } = useAgGridCalculatedHeight(containerRef, 65, false);
  const { resizeColumns } = useAgGridResizeColumns(gridRef, containerRef);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [datasetRequest, setDatasetRequest] = useState<UseDatasetDataRequest | null>(null);
  const [setAgGridRowData, setSetAgGridRowData] = useState<SetAgGridRowData | null>(null);
  const [onRefreshStaleDataset, setOnRefreshStaleDataset] = useState<(() => void) | null>(null);

  const { data: datasetData, isFetching: isFetchingDatasetData } = useDatasetData(datasetRequest);

  const { refetch: getDatasetExportData } = useDatasetData(
    datasetRequest ? { ...datasetRequest, startRow: null, endRow: null, isExport: true } : null,
    { disabled: true },
  );

  const {
    data: datasetMetadata,
    refetch: refetchDatasetMetadata,
    isFetching: isFetchingDatasetMetadata,
  } = useDatasetMetadata({
    batchId: batchId ?? '',
    datasetName: currentDataset,
  });

  const { schema, mappingSchema, columnDefs } = useDatasetSchema({
    batchMetadata,
    datasetMetadata,
    currentDataset,
    isSsrmDataGrid: true,
  });

  const datasetFilterModal = datasetRequest?.filterModel;

  const isFetchingDataset = isFetchingDatasetData || isFetchingDatasetMetadata;

  /** use as a part of queryKey, so we can enforce a data update when this changes */
  const fetchCounter = useRef(0);

  const serverSideDatasource = useMemo<IServerSideDatasource | undefined>(() => {
    if (!batchId) return undefined;

    return {
      getRows: (params) => {
        const { request, success } = params;

        fetchCounter.current += 1;

        setDatasetRequest({
          batchId,
          datasetName: currentDataset,
          startRow: request.startRow,
          endRow: request.endRow,
          filterModel: Object.entries(request.filterModel || {}).reduce(
            (prev, [key, { values }]) => ({ ...prev, [key]: values }),
            {},
          ),
          sortModel: request.sortModel.map((o) => ({
            column: o.colId,
            ascDesc: o.sort.toUpperCase() as AscDescEnum,
          })),
          isExport: false,
          counter: fetchCounter.current,
        });

        refetchDatasetMetadata();

        setSetAgGridRowData(() => (params: LoadSuccessParams) => {
          success(params);

          /** display no rows overlay in data grid */
          if (params.rowData.length === 0) {
            setTimeout(() => gridRef.current?.api.showNoRowsOverlay(), 100);
          }
        });
      },
    };
  }, [batchId, currentDataset, refetchDatasetMetadata]);

  const rowSelection = useMemo<RowSelectionOptions>(
    () => ({ mode: 'multiRow', headerCheckbox: false }),
    [],
  );

  const cellSelection = useMemo<CellSelectionOptions>(() => ({ handle: { mode: 'range' } }), []);

  const onColumnPivotModeChanged = useCallback(
    (e: ColumnPivotModeChangedEvent) => setCurrentGridPivotMode(e.api.isPivotMode()),
    [setCurrentGridPivotMode],
  );

  const onToolPanelVisibleChanged = useCallback(
    (e: ToolPanelVisibleChangedEvent) => setCurrentGridToolPanel(e.visible ? e.key : undefined),
    [setCurrentGridToolPanel],
  );

  /** refresh data grid and refetch everything if current dataset is marked as stale */
  useEffect(() => {
    if (ssrmStaleDatasets.has(currentDataset)) {
      gridRef.current?.api?.refreshServerSide({ purge: true });
      setSsrmStaleDatasets(new Set([...ssrmStaleDatasets].filter((o) => o !== currentDataset)));
      onRefreshStaleDataset?.();
    }
  }, [currentDataset, onRefreshStaleDataset, setSsrmStaleDatasets, ssrmStaleDatasets]);

  return {
    gridRef,
    containerRef,
    gridHeight,
    schema,
    mappingSchema,
    columnDefs,
    datasetData,
    datasetMetadata,
    datasetFilterModal,
    isFetchingDataset,
    serverSideDatasource,
    rowSelection,
    cellSelection,
    isFullScreen,
    resizeColumns,
    onColumnPivotModeChanged,
    onToolPanelVisibleChanged,
    setIsFullScreen,
    setAgGridRowData,
    setOnRefreshStaleDataset,
    getDatasetExportData,
  };
};
