import { useParams } from 'react-router-dom';
import DatasetTabsViewer from 'src/pages/commons/plan-views/DatasetTabsViewer';
import PlanInputDataGrid from 'src/pages/plan-manager-page/plan-input-tab/components/PlanInputDataGrid';
import {
  getInputDatasetOptions,
  getInputSubTabs,
} from 'src/pages/plan-manager-page/plan-input-tab/planInputConfig';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { PlanTypeId } from 'src/utils/planning/planetModel';
import PlanInputSsrmGrid from 'src/pages/plan-manager-page/plan-input-tab/components/PlanInputSsrmGrid';

const PlanInputTab = () => {
  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({ batchId });
  const { data: featureFlags } = useFeatureFlags();

  return (
    <DatasetTabsViewer
      getSubTabs={getInputSubTabs}
      getDatasetOptions={getInputDatasetOptions}
      renderGridComponent={(o) => {
        if (
          featureFlags?.feServerSidePaginationUtrProductivityInput &&
          batchMetadata?.costType === PlanTypeId.UTR_PRODUCTIVITY &&
          !o.currentGridPivotMode
        ) {
          return <PlanInputSsrmGrid {...o} key={o.currentDataset} />;
        }

        return <PlanInputDataGrid {...o} key={o.currentDataset} />;
      }}
    />
  );
};

export default PlanInputTab;
