import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import {
  AsyncUploadEditsStatusEnumEnum,
  ComputeStatusEnumEnum,
} from '@amzn/fox-den-cost-planning-lambda';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { isArray, isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QUERY_KEYS } from 'src/api/api-utils';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useNotificationContext } from 'src/hooks/useNotificationContext';
import { UTRLargeDatasetNames } from 'src/pages/plan-manager-page/plan-input-tab/components/PlanInputDataGrid';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import { SupportedDatasetType, TriggerType } from 'src/utils/planning/planetModel';
import {
  SetSupportedDatasetsState,
  usePlanPageContext,
} from 'src/pages/plan-manager-page/PlanManagerPage';
import { ErrorModalInfo } from 'src/providers/NotificationProvider';

const COMPUTE_STATUS_POLLING_TIME = 5 * 1000;

enum BriefProcessStatus {
  Processing = 'Processing',
  Success = 'Success',
  Failure = 'Failure',
}

const getBriefComputeStatus = (
  batchMetadata: IBatchMetadataParsed | undefined,
): BriefProcessStatus | null => {
  if (!batchMetadata?.computeStatus?.status) return null;

  const statusMap: Record<ComputeStatusEnumEnum, BriefProcessStatus> = {
    [ComputeStatusEnumEnum.Success]: BriefProcessStatus.Success,
    [ComputeStatusEnumEnum.Cancelled]: BriefProcessStatus.Success,
    [ComputeStatusEnumEnum.Warning]: BriefProcessStatus.Success,
    [ComputeStatusEnumEnum.Failure]: BriefProcessStatus.Failure,
    [ComputeStatusEnumEnum.Timeout]: BriefProcessStatus.Failure,
    [ComputeStatusEnumEnum.InProgress]: BriefProcessStatus.Processing,
    [ComputeStatusEnumEnum.Cancelling]: BriefProcessStatus.Processing,
    [ComputeStatusEnumEnum.Queued]: BriefProcessStatus.Processing,
  };

  return statusMap[batchMetadata.computeStatus.status as ComputeStatusEnumEnum];
};

const getBriefAsyncUploadEditsStatus = (
  batchMetadata: IBatchMetadataParsed | undefined,
  trackedAsyncUploadEditsDatasets?: Set<SupportedDatasetType>,
): BriefProcessStatus | null => {
  if (!batchMetadata?.asyncUploadEditsStatus?.status) return null;

  const statusMap: Record<AsyncUploadEditsStatusEnumEnum, BriefProcessStatus> = {
    [AsyncUploadEditsStatusEnumEnum.Success]: BriefProcessStatus.Success,
    [AsyncUploadEditsStatusEnumEnum.Failure]: BriefProcessStatus.Failure,
    [AsyncUploadEditsStatusEnumEnum.Timeout]: BriefProcessStatus.Failure,
    [AsyncUploadEditsStatusEnumEnum.Cancelled]: BriefProcessStatus.Failure,
    [AsyncUploadEditsStatusEnumEnum.Queued]: BriefProcessStatus.Processing,
    [AsyncUploadEditsStatusEnumEnum.InProgress]: BriefProcessStatus.Processing,
  };

  const briefOverallStatus = statusMap[batchMetadata.asyncUploadEditsStatus.status];

  /** if overall status is failure but tracking datasets are actually all success, treat as success */
  if (briefOverallStatus === BriefProcessStatus.Failure && trackedAsyncUploadEditsDatasets?.size) {
    for (const dataset of trackedAsyncUploadEditsDatasets) {
      const datasetStatus = batchMetadata.asyncUploadEditsStatus.detailedStatus?.[dataset].status;
      const briefDatasetStatus = statusMap[datasetStatus as AsyncUploadEditsStatusEnumEnum];
      if (briefDatasetStatus === BriefProcessStatus.Failure) return BriefProcessStatus.Failure;
    }
    return BriefProcessStatus.Success;
  }

  return briefOverallStatus;
};

const getComputeStatusMessage = (
  batchMetadata: IBatchMetadataParsed | undefined,
  t: TFunction<'translation', undefined>,
) => {
  const trigger = batchMetadata?.computeStatus?.trigger as TriggerType | undefined;
  const briefComputeStatus = getBriefComputeStatus(batchMetadata);

  if (!trigger || !briefComputeStatus) return null;

  const triggerTypeToComputeMessageMap: Record<TriggerType, Record<BriefProcessStatus, string>> = {
    [TriggerType.CAPEX_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.CAPEX_EXTRACT_OUTPUT]: {
      [BriefProcessStatus.Processing]: t('redshift_extract_in_progress'),
      [BriefProcessStatus.Success]: t('redshift_extract_success'),
      [BriefProcessStatus.Failure]: t('redshift_extract_failure'),
    },
    [TriggerType.DEPRECIATION_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.DEPRECIATION_EXTRACT_OUTPUT]: {
      [BriefProcessStatus.Processing]: t('redshift_extract_in_progress'),
      [BriefProcessStatus.Success]: t('redshift_extract_success'),
      [BriefProcessStatus.Failure]: t('redshift_extract_failure'),
    },
    [TriggerType.REAL_ESTATE_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.REAL_ESTATE_EXTRACT_OUTPUT]: {
      [BriefProcessStatus.Processing]: t('redshift_extract_in_progress'),
      [BriefProcessStatus.Success]: t('redshift_extract_success'),
      [BriefProcessStatus.Failure]: t('redshift_extract_failure'),
    },
    [TriggerType.JANITORIAL_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.SECURITY_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.OTR_WHT_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.OTR_WHT_EXTRACT_OUTPUT]: {
      [BriefProcessStatus.Processing]: t('redshift_extract_in_progress'),
      [BriefProcessStatus.Success]: t('redshift_extract_success'),
      [BriefProcessStatus.Failure]: t('redshift_extract_failure'),
    },
    [TriggerType.OTR_DIB_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.OTR_DIB_EXTRACT_OUTPUT]: {
      [BriefProcessStatus.Processing]: t('redshift_extract_in_progress'),
      [BriefProcessStatus.Success]: t('redshift_extract_success'),
      [BriefProcessStatus.Failure]: t('redshift_extract_failure'),
    },
    [TriggerType.OTR_FC_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.OTR_FC_EXTRACT_OUTPUT]: {
      [BriefProcessStatus.Processing]: t('redshift_extract_in_progress'),
      [BriefProcessStatus.Success]: t('redshift_extract_success'),
      [BriefProcessStatus.Failure]: t('redshift_extract_failure'),
    },
    [TriggerType.OTR_OB_AUTOMATED_DATASET_IMPORT]: {
      [BriefProcessStatus.Processing]: t('automated_input_import_in_progress'),
      [BriefProcessStatus.Success]: t('automated_input_import_success'),
      [BriefProcessStatus.Failure]: t('automated_input_import_failure'),
    },
    [TriggerType.OTR_OB_CALL_FORECAST_RECONCILIATION]: {
      [BriefProcessStatus.Processing]: t('forecast_reconciliation_in_progress'),
      [BriefProcessStatus.Success]: t('forecast_reconciliation_success'),
      [BriefProcessStatus.Failure]: t('forecast_reconciliation_failure'),
    },
    [TriggerType.OTR_OB_CALL_INTEGRATED_COMPUTATION_LOGIC]: {
      [BriefProcessStatus.Processing]: t('integrated_computation_logic_in_progress'),
      [BriefProcessStatus.Success]: t('integrated_computation_logic_success'),
      [BriefProcessStatus.Failure]: t('integrated_computation_logic_failure'),
    },
    [TriggerType.THREE_P_TRANSPORTATION_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.THREE_P_TRANSPORTATION_EXTRACT_OUTPUT]: {
      [BriefProcessStatus.Processing]: t('redshift_extract_in_progress'),
      [BriefProcessStatus.Success]: t('redshift_extract_success'),
      [BriefProcessStatus.Failure]: t('redshift_extract_failure'),
    },
    [TriggerType.TOPS_DOWN_FORECAST_CREATE_PLAN]: {
      [BriefProcessStatus.Processing]: t('initialize_plan_in_progress'),
      [BriefProcessStatus.Success]: t('initialize_plan_success'),
      [BriefProcessStatus.Failure]: t('initialize_plan_failure'),
    },
    [TriggerType.TOPS_DOWN_FORECAST_BASELINE_IMPORT]: {
      [BriefProcessStatus.Processing]: t('baseline_import_in_progress'),
      [BriefProcessStatus.Success]: t('baseline_import_success'),
      [BriefProcessStatus.Failure]: t('baseline_import_failure'),
    },
    [TriggerType.TOPS_DOWN_FORECAST_COMPUTE]: {
      [BriefProcessStatus.Processing]: t('compute_plan_in_progress'),
      [BriefProcessStatus.Success]: t('compute_plan_success'),
      [BriefProcessStatus.Failure]: t('compute_plan_failure'),
    },
    [TriggerType.TOPS_DOWN_FORECAST_COMPUTE_FROM_MM]: {
      [BriefProcessStatus.Processing]: t('compute_plan_in_progress'),
      [BriefProcessStatus.Success]: t('compute_plan_success'),
      [BriefProcessStatus.Failure]: t('compute_plan_failure'),
    },
    [TriggerType.TOPS_DOWN_FORECAST_COMPUTE_FROM_CPU]: {
      [BriefProcessStatus.Processing]: t('compute_plan_in_progress'),
      [BriefProcessStatus.Success]: t('compute_plan_success'),
      [BriefProcessStatus.Failure]: t('compute_plan_failure'),
    },
    [TriggerType.TOPS_DOWN_FORECAST_LUNA_EXPORT]: {
      [BriefProcessStatus.Processing]: t('export_data_in_progress'),
      [BriefProcessStatus.Success]: t('export_data_success'),
      [BriefProcessStatus.Failure]: t('export_data_failure'),
    },
    [TriggerType.TOPS_DOWN_FORECAST_VOLUME_DATASET_IMPORT]: {
      [BriefProcessStatus.Processing]: t('import_data_in_progress'),
      [BriefProcessStatus.Success]: t('import_data_success'),
      [BriefProcessStatus.Failure]: t('import_data_failure'),
    },
    [TriggerType.TOPS_DOWN_FORECAST_BASELINE_CPU_DATASET_IMPORT]: {
      [BriefProcessStatus.Processing]: t('import_data_in_progress'),
      [BriefProcessStatus.Success]: t('import_data_success'),
      [BriefProcessStatus.Failure]: t('import_data_failure'),
    },
    [TriggerType.FIXED_COST_CONSOLIDATION_CREATE_PLAN]: {
      [BriefProcessStatus.Processing]: t('initialize_plan_in_progress'),
      [BriefProcessStatus.Success]: t('initialize_plan_success'),
      [BriefProcessStatus.Failure]: t('initialize_plan_failure'),
    },
    [TriggerType.FIXED_COST_CONSOLIDATION_COMPUTE]: {
      [BriefProcessStatus.Processing]: t('compute_plan_in_progress'),
      [BriefProcessStatus.Success]: t('compute_plan_success'),
      [BriefProcessStatus.Failure]: t('compute_plan_failure'),
    },
    [TriggerType.CONSOLIDATION_BASELINE]: {
      [BriefProcessStatus.Processing]: t('baseline_load_in_progress'),
      [BriefProcessStatus.Success]: t('baseline_load_success'),
      [BriefProcessStatus.Failure]: t('baseline_load_failure'),
    },
    [TriggerType.BASELINE_LOAD]: {
      [BriefProcessStatus.Processing]: t('baseline_load_in_progress'),
      [BriefProcessStatus.Success]: t('baseline_load_success'),
      [BriefProcessStatus.Failure]: t('baseline_load_failure'),
    },
    [TriggerType.UTR_PRODUCTIVITY_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.UTR_PRODUCTIVITY_EXTRACT_OUTPUT]: {
      [BriefProcessStatus.Processing]: t('redshift_extract_in_progress'),
      [BriefProcessStatus.Success]: t('redshift_extract_success'),
      [BriefProcessStatus.Failure]: t('redshift_extract_failure'),
    },
    [TriggerType.UTR_COST_CALL_PROCEDURE]: {
      [BriefProcessStatus.Processing]: t('stored_procedure_in_progress'),
      [BriefProcessStatus.Success]: t('stored_procedure_success'),
      [BriefProcessStatus.Failure]: t('stored_procedure_failure'),
    },
    [TriggerType.UTR_COST_EXTRACT_OUTPUT]: {
      [BriefProcessStatus.Processing]: t('redshift_extract_in_progress'),
      [BriefProcessStatus.Success]: t('redshift_extract_success'),
      [BriefProcessStatus.Failure]: t('redshift_extract_failure'),
    },
    [TriggerType.CF_CONSOLIDATION_COMPUTE]: {
      [BriefProcessStatus.Processing]: t('compute_and_export_plan_in_progress'),
      [BriefProcessStatus.Success]: t('compute_and_export_plan_success'),
      [BriefProcessStatus.Failure]: t('compute_and_export_plan_failure'),
    },
    [TriggerType.CF_CONSOLIDATION_BIFROST_EXPORT]: {
      [BriefProcessStatus.Processing]: t('export_data_in_progress'),
      [BriefProcessStatus.Success]: t('export_data_success'),
      [BriefProcessStatus.Failure]: t('export_data_failure'),
    },
  };

  if (
    briefComputeStatus === BriefProcessStatus.Processing &&
    batchMetadata?.computeStatus?.currentStep
  ) {
    return t('computation_step_in_progress', { step: batchMetadata.computeStatus.currentStep });
  }

  return triggerTypeToComputeMessageMap[trigger]?.[briefComputeStatus];
};

// Todo: Make this make more sense
const getComputeErrorModalInfo = (
  batchMetadata: IBatchMetadataParsed | undefined,
  t: TFunction<'translation', undefined>,
): ErrorModalInfo => {
  const detailedSteps = batchMetadata?.computeStatus?.detailedSteps;

  const tableItems =
    detailedSteps && isArray(detailedSteps)
      ? detailedSteps.map((stepItem) => {
          try {
            const errorObject = JSON.parse(stepItem.detail!);
            return {
              ...stepItem,
              detail: errorObject.errorMessageBody,
              downloadLink: errorObject.errorMessageLocation,
            };
          } catch (_) {
            return { ...stepItem, detail: stepItem.detail!, downloadLink: null };
          }
        })
      : [];

  const tableColumnDefinitions: TableProps.ColumnDefinition<(typeof tableItems)[0]>[] = [
    { id: 'stepName', header: t('step_name_header'), cell: (item) => item.stepName },
    { id: 'status', header: t('status_header'), cell: (item) => item.status },
    { id: 'detail', header: t('details_header'), cell: (item) => item.detail },
  ];

  let downloadLink: string | null = null;

  for (let i = tableItems.length - 1; i >= 0; i--) {
    const item = tableItems[i];
    if (item.downloadLink) {
      downloadLink = item.downloadLink;
      break;
    }
  }

  return {
    message: t('global_error_modal_default_message'),
    table: {
      items: tableItems,
      columnDefinitions: tableColumnDefinitions,
    },
    downloadLink,
  };
};

const getAsyncUploadEditsErrorModalInfo = (
  batchMetadata: IBatchMetadataParsed | undefined,
  t: TFunction<'translation', undefined>,
): ErrorModalInfo => {
  const tableItems = Object.entries(batchMetadata?.asyncUploadEditsStatus?.detailedStatus || {})
    .map(([datasetName, attributes]) => ({ datasetName, ...attributes }))
    .filter(
      (o) =>
        o.status === AsyncUploadEditsStatusEnumEnum.Failure ||
        o.status === AsyncUploadEditsStatusEnumEnum.Timeout,
    );

  const tableColumnDefinitions: TableProps.ColumnDefinition<(typeof tableItems)[0]>[] = [
    { id: 'datasetName', header: t('dataset_name_header'), cell: (item) => item.datasetName },
    { id: 'status', header: t('status_header'), cell: (item) => item.status },
    { id: 'detail', header: t('details_header'), cell: (item) => item.detail },
  ];

  return {
    message: t('global_error_modal_default_message'),
    table: {
      items: tableItems,
      columnDefinitions: tableColumnDefinitions,
    },
  };
};

const refetchDatasetsAfterComputeSuccess = (
  batchMetadata: IBatchMetadataParsed,
  queryClient: QueryClient,
  setSsrmStaleDatasets: SetSupportedDatasetsState,
) => {
  if (!batchMetadata.computeStatus?.trigger) return;

  const trigger = batchMetadata.computeStatus.trigger as TriggerType;

  const triggerTypeToRefetchDatasetsMap: Record<TriggerType, SupportedDatasetType[]> = {
    [TriggerType.CAPEX_CALL_PROCEDURE]: [SupportedDatasetType.CAPEX_PLANNING_FINAL_OUTPUT],
    [TriggerType.CAPEX_EXTRACT_OUTPUT]: [SupportedDatasetType.CAPEX_PLANNING_FINAL_OUTPUT],
    [TriggerType.DEPRECIATION_CALL_PROCEDURE]: [SupportedDatasetType.DEPRECIATION_OUTPUT_PLAN],
    [TriggerType.DEPRECIATION_EXTRACT_OUTPUT]: [SupportedDatasetType.DEPRECIATION_OUTPUT_PLAN],
    [TriggerType.REAL_ESTATE_CALL_PROCEDURE]: [SupportedDatasetType.REAL_ESTATE_OUTPUT_PLAN],
    [TriggerType.REAL_ESTATE_EXTRACT_OUTPUT]: [SupportedDatasetType.REAL_ESTATE_OUTPUT_PLAN],
    [TriggerType.JANITORIAL_CALL_PROCEDURE]: [SupportedDatasetType.JANITORIAL_OUTPUT_PLAN],
    [TriggerType.SECURITY_CALL_PROCEDURE]: [
      SupportedDatasetType.SECURITY_OUTPUT_PLANET_SECURITY_PLAN,
    ],
    [TriggerType.THREE_P_TRANSPORTATION_CALL_PROCEDURE]: [
      SupportedDatasetType.THREE_P_TRANSPORTATION_OUTPUT_PLANET_3P_PLAN_WW,
    ],
    [TriggerType.THREE_P_TRANSPORTATION_EXTRACT_OUTPUT]: [
      SupportedDatasetType.THREE_P_TRANSPORTATION_OUTPUT_PLANET_3P_PLAN_WW,
    ],
    [TriggerType.OTR_WHT_CALL_PROCEDURE]: [SupportedDatasetType.PLANET_OTR_WHT_OUTPUT_MAIN_COST],
    [TriggerType.OTR_WHT_EXTRACT_OUTPUT]: [SupportedDatasetType.PLANET_OTR_WHT_OUTPUT_MAIN_COST],
    [TriggerType.OTR_DIB_CALL_PROCEDURE]: [SupportedDatasetType.PLANET_OTR_DIB_OUTPUT],
    [TriggerType.OTR_DIB_EXTRACT_OUTPUT]: [SupportedDatasetType.PLANET_OTR_DIB_OUTPUT],
    [TriggerType.OTR_FC_CALL_PROCEDURE]: [SupportedDatasetType.PLANET_OTR_FC_OUTPUT_WW],
    [TriggerType.OTR_FC_EXTRACT_OUTPUT]: [SupportedDatasetType.PLANET_OTR_FC_OUTPUT_WW],
    [TriggerType.OTR_OB_AUTOMATED_DATASET_IMPORT]: [
      SupportedDatasetType.PLANET_OTR_OB_HISTORICAL_LOADS,
      SupportedDatasetType.PLANET_OTR_OB_LDT_ACTUALS,
      SupportedDatasetType.PLANET_OTR_OB_SPEED_ACTUALS,
      SupportedDatasetType.PLANET_OTR_OB_CORRIDOR_ALLOC,
    ],
    [TriggerType.OTR_OB_CALL_FORECAST_RECONCILIATION]: [
      SupportedDatasetType.PLANET_OTR_OB_OUTPUT_LOADS_TRANSFORMED,
    ],
    [TriggerType.OTR_OB_CALL_INTEGRATED_COMPUTATION_LOGIC]: [
      SupportedDatasetType.PLANET_OTR_OB_OUTPUT_LOADS_TRANSFORMED,
    ],
    [TriggerType.TOPS_DOWN_FORECAST_CREATE_PLAN]: [],
    [TriggerType.TOPS_DOWN_FORECAST_BASELINE_IMPORT]: [
      SupportedDatasetType.TOPS_DOWN_FORECAST_KPI_BASELINE,
    ],
    [TriggerType.TOPS_DOWN_FORECAST_COMPUTE]: [
      SupportedDatasetType.TOPS_DOWN_FORECAST_CPU_FORECAST,
      SupportedDatasetType.TOPS_DOWN_FORECAST_MM_FORECAST,
      SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT,
    ],
    [TriggerType.TOPS_DOWN_FORECAST_COMPUTE_FROM_MM]: [
      SupportedDatasetType.TOPS_DOWN_FORECAST_CPU_FORECAST,
      SupportedDatasetType.TOPS_DOWN_FORECAST_MM_FORECAST,
      SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT,
    ],
    [TriggerType.TOPS_DOWN_FORECAST_COMPUTE_FROM_CPU]: [
      SupportedDatasetType.TOPS_DOWN_FORECAST_CPU_FORECAST,
    ],
    [TriggerType.TOPS_DOWN_FORECAST_LUNA_EXPORT]: [],
    [TriggerType.TOPS_DOWN_FORECAST_VOLUME_DATASET_IMPORT]: [
      SupportedDatasetType.TOPS_DOWN_FORECAST_VOLUME,
    ],
    [TriggerType.TOPS_DOWN_FORECAST_BASELINE_CPU_DATASET_IMPORT]: [
      SupportedDatasetType.TOPS_DOWN_FORECAST_Q2G_CPU,
      SupportedDatasetType.TOPS_DOWN_FORECAST_VOLUME,
      SupportedDatasetType.TOPS_DOWN_FORECAST_KPI_BASELINE,
    ],
    [TriggerType.FIXED_COST_CONSOLIDATION_CREATE_PLAN]: [],
    [TriggerType.FIXED_COST_CONSOLIDATION_COMPUTE]: [],
    [TriggerType.CONSOLIDATION_BASELINE]: [],
    [TriggerType.UTR_PRODUCTIVITY_CALL_PROCEDURE]: [
      SupportedDatasetType.UTR_PRODUCTIVITY_OUTPUT_PLAN,
    ],
    [TriggerType.UTR_PRODUCTIVITY_EXTRACT_OUTPUT]: [
      SupportedDatasetType.UTR_PRODUCTIVITY_OUTPUT_PLAN,
    ],
    [TriggerType.UTR_COST_CALL_PROCEDURE]: [SupportedDatasetType.PLANET_UTR_COST_HC_OUTPUT_PLAN],
    [TriggerType.UTR_COST_EXTRACT_OUTPUT]: [SupportedDatasetType.PLANET_UTR_COST_HC_OUTPUT_PLAN],
    [TriggerType.BASELINE_LOAD]: [],
    [TriggerType.CF_CONSOLIDATION_COMPUTE]: [SupportedDatasetType.CF_CONSOLIDATION_COMBINED_OUTPUT],
    [TriggerType.CF_CONSOLIDATION_BIFROST_EXPORT]: [],
  };

  const datasetsToRefetch = triggerTypeToRefetchDatasetsMap[trigger] || [];

  datasetsToRefetch.forEach((dataset) => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.PLAN_DATASET, batchMetadata.batchId, dataset],
    });
    setSsrmStaleDatasets((prev) => new Set([...prev, dataset]));
  });
};

const refetchDatasetsAfterAsyncUploadEditsSuccess = (
  batchMetadata: IBatchMetadataParsed,
  trackedAsyncUploadEditsDatasets: Set<SupportedDatasetType>,
  queryClient: QueryClient,
  setSsrmStaleDatasets: SetSupportedDatasetsState,
) => {
  for (const dataset of trackedAsyncUploadEditsDatasets) {
    /** only large datasets need to be refetched, as they are the only ones uploaded through asyncUploadEdits */
    if (UTRLargeDatasetNames.has(dataset)) {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PLAN_DATASET, batchMetadata.batchId, dataset],
      });
      setSsrmStaleDatasets((prev) => new Set([...prev, dataset]));
    }
  }
};

/** need to keep polling if computeStatus/asyncUploadEditsStatus is still processing */
export const useIsPlanPolling = (batchMetadata: IBatchMetadataParsed | undefined) => {
  const isComputeProcessing =
    getBriefComputeStatus(batchMetadata) === BriefProcessStatus.Processing;

  const isAsyncUploadEditsProcessing =
    getBriefAsyncUploadEditsStatus(batchMetadata) === BriefProcessStatus.Processing;

  const isPlanPolling = isComputeProcessing || isAsyncUploadEditsProcessing;

  const processingComputeTrigger = isComputeProcessing
    ? (batchMetadata?.computeStatus?.trigger as TriggerType)
    : undefined;

  return { isPlanPolling, processingComputeTrigger };
};

/** make sure we get metadata with the latest compute status */
const usePollingBatchMetadata = (batchId: string | undefined) => {
  const queryClient = useQueryClient();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { isPlanPolling } = useIsPlanPolling(batchMetadata);

  /** keep fetching the latest computeStatus/asyncUploadEditsStatus periodically */
  const { data: partialBatchMetadata } = useBatchMetadata(
    { batchId, requestFieldList: ['computeStatus'] },
    { refetchInterval: COMPUTE_STATUS_POLLING_TIME, disabled: !isPlanPolling },
  );

  /** if fullBatchMetadata has outdated computeStatus/asyncUploadEditsStatus, refetch fullBatchMetadata */
  useEffect(() => {
    if (!batchMetadata || !partialBatchMetadata) return;

    if (
      !isEqual(batchMetadata.computeStatus, partialBatchMetadata.computeStatus) ||
      !isEqual(batchMetadata.asyncUploadEditsStatus, partialBatchMetadata.asyncUploadEditsStatus)
    ) {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BATCH_METADATA, batchId] });
    }
  }, [batchMetadata, partialBatchMetadata, batchId, queryClient]);

  return { batchMetadata };
};

export const useComputationPollingManager = (batchId: string | undefined) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { addNotification, addNotificationWithErrorModal } = useNotificationContext();

  const {
    trackedAsyncUploadEditsDatasets,
    setTrackedAsyncUploadEditsDatasets,
    setSsrmStaleDatasets,
  } = usePlanPageContext();

  const { batchMetadata } = usePollingBatchMetadata(batchId);

  const [computePollingMessage, setComputePollingMessage] = useState<string | null>(null);
  const [computePollingBatchId, setComputePollingBatchId] = useState(batchId);

  /** handle computeStatus notifications & query invalidation */
  useEffect(() => {
    /** if no batch metadata exists, return early  */
    if (!batchMetadata) return;

    const statusMessage = getComputeStatusMessage(batchMetadata, t);

    if (!statusMessage || computePollingMessage === statusMessage) return;

    if (getBriefComputeStatus(batchMetadata) === BriefProcessStatus.Processing) {
      setComputePollingMessage(statusMessage);
      setComputePollingBatchId(batchMetadata?.batchId ?? undefined);
      return;
    }

    if (computePollingBatchId !== batchMetadata?.batchId) {
      setComputePollingMessage(null);
      setComputePollingBatchId(batchMetadata?.batchId ?? undefined);
      return;
    }

    if (!computePollingMessage) return;

    if (getBriefComputeStatus(batchMetadata) === BriefProcessStatus.Success) {
      setComputePollingMessage(null);
      addNotification({ content: statusMessage, type: 'success' });
      refetchDatasetsAfterComputeSuccess(batchMetadata, queryClient, setSsrmStaleDatasets);
    }

    if (getBriefComputeStatus(batchMetadata) === BriefProcessStatus.Failure) {
      setComputePollingMessage(null);
      addNotificationWithErrorModal({
        content: statusMessage,
        errorModal: getComputeErrorModalInfo(batchMetadata, t),
      });
    }
  }, [
    addNotificationWithErrorModal,
    addNotification,
    computePollingMessage,
    computePollingBatchId,
    batchMetadata,
    queryClient,
    t,
    setSsrmStaleDatasets,
  ]);

  const [asyncUploadPollingMessage, setAsyncUploadPollingMessage] = useState<string | null>(null);
  const [asyncUploadPollingBatchId, setAsyncUploadPollingBatchId] = useState<string | null>(null);

  /** handle asyncUploadEditsStatus notifications & query invalidation */
  useEffect(() => {
    /** if no batch metadata exists, return early  */
    if (!batchMetadata) return;

    /** translation string ids for notification messages */
    const inProgressMessage = t('redshift_load_in_progress');
    const successMessage = t('redshift_load_success');
    const failureMessage = t('redshift_load_failure');

    const briefAsyncUploadEditsStatus = getBriefAsyncUploadEditsStatus(
      batchMetadata,
      trackedAsyncUploadEditsDatasets,
    );

    /** if is processing, set up polling notification and return */
    if (briefAsyncUploadEditsStatus === BriefProcessStatus.Processing) {
      if (asyncUploadPollingMessage) return;
      setAsyncUploadPollingMessage(inProgressMessage);
      setAsyncUploadPollingBatchId(batchMetadata.batchId!);
      return;
    }

    /** if is not processing, and no polling notification exists, do nothing and return */
    if (!asyncUploadPollingMessage) return;

    /** if is not processing, and polling notification exists, clear polling notification */
    setAsyncUploadPollingMessage(null);
    setAsyncUploadPollingBatchId(null);

    /** if plan id has changed, do nothing and return */
    if (asyncUploadPollingBatchId !== batchMetadata?.batchId) return;

    /** if previously in processing plan turns into success, set up success notification */
    if (briefAsyncUploadEditsStatus === BriefProcessStatus.Success) {
      addNotification({ content: successMessage, type: 'success' });
      refetchDatasetsAfterAsyncUploadEditsSuccess(
        batchMetadata,
        trackedAsyncUploadEditsDatasets,
        queryClient,
        setSsrmStaleDatasets,
      );
    }

    /** if previously in processing plan turns into failure, set up failure notification */
    if (briefAsyncUploadEditsStatus === BriefProcessStatus.Failure) {
      addNotificationWithErrorModal({
        content: failureMessage,
        errorModal: getAsyncUploadEditsErrorModalInfo(batchMetadata, t),
      });
    }

    setTrackedAsyncUploadEditsDatasets(new Set());
  }, [
    addNotificationWithErrorModal,
    addNotification,
    asyncUploadPollingBatchId,
    asyncUploadPollingMessage,
    batchMetadata,
    queryClient,
    t,
    trackedAsyncUploadEditsDatasets,
    setTrackedAsyncUploadEditsDatasets,
    setSsrmStaleDatasets,
  ]);

  /** all polling notifications */
  const pollingNotifications = [computePollingMessage, asyncUploadPollingMessage].filter((o) => o);

  return { pollingNotifications };
};
