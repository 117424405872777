import {
  Alert,
  Box,
  Container,
  DateRangePicker,
  Header,
  Popover,
  Select,
} from '@amzn/awsui-components-react/polaris';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { DateRangePickerProps } from '@amzn/awsui-components-react/polaris/date-range-picker';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons/lib';
import { Fragment, PropsWithChildren, ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';
import { DatasetOption } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import { DateRangePickerChangeEvent, SelectChangeEvent } from 'src/common/EventType';
import {
  useTriggerTopsDownVolumeDatasetImport,
  useTriggerTopsDownBaselineCpuDatasetImport,
  useTriggerOtrObAutomatedDatasetImport,
} from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { useBatchMetadataMutation } from 'src/api/mutation/useBatchMetadataMutation';
import { usePortfolioIds, useScenarios } from 'src/api/query/useBatchDimensionValues';
import { buildComputeParams } from 'src/api/mutation/useTriggerComputeMutation';

interface ActionButtonProps {
  icon: IconType;
  text: string;
  onClick: () => void;
  disabled?: boolean | string;
  hide?: boolean;
}

export type DataGridActionButtonGroups = ActionButtonProps[][];

interface DatasetSelectorProps {
  currentDataset: SupportedDatasetType;
  onDatasetSelected: (e: SelectChangeEvent) => void;
  datasetOptions: DatasetOption[];
}

interface ButtonGroupsProps {
  actionButtonGroups: DataGridActionButtonGroups;
}

interface HeaderProps {
  headerText: ReactNode;
  description: ReactNode;
}

type HeaderActionsProps = DatasetSelectorProps & ButtonGroupsProps;

type DataGridContainerProps = HeaderActionsProps & HeaderProps & PropsWithChildren;

interface AdditionalSelectorProps {
  options: SelectProps['options'];
  selectedOption: SelectProps['selectedOption'];
  onChange: SelectProps['onChange'];
  placeholder: SelectProps['placeholder'];
}

interface AdditionalDateRangePickerProps {
  value: DateRangePickerProps['value'];
  onChange: DateRangePickerProps['onChange'];
  placeholder: DateRangePickerProps['placeholder'];
  disabled?: boolean;
}

const OTRAutomatedImportDatasetsWithDataRangeSelector: Set<SupportedDatasetType> = new Set([
  SupportedDatasetType.PLANET_OTR_OB_HISTORICAL_LOADS,
  SupportedDatasetType.PLANET_OTR_OB_LDT_ACTUALS,
  SupportedDatasetType.PLANET_OTR_OB_SPEED_ACTUALS,
]);

export const OTRAutomatedImportDatasetsWithTrailingSelector: Set<SupportedDatasetType> = new Set([
  SupportedDatasetType.PLANET_OTR_OB_CORRIDOR_ALLOC,
]);

const AdditionalSelect = (props: AdditionalSelectorProps) => (
  <div style={{ width: 180 }}>
    <Select
      options={props.options}
      selectedOption={props.selectedOption}
      onChange={props.onChange}
      placeholder={props.placeholder}
      filteringPlaceholder={props.placeholder}
      filteringType="auto"
    />
  </div>
);

const AdditionalDateRangePicker = (props: AdditionalDateRangePickerProps) => {
  const { t } = useTranslation();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <div style={{ width: 250 }}>
      <DateRangePicker
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        isValidRange={(range) => {
          if (range?.type === 'relative') {
            return { valid: false, errorMessage: t('date_range_picker_error_message_no_relative') };
          }

          if (!range || !range.startDate || !range.endDate) {
            return { valid: false, errorMessage: t('date_range_picker_error_message_incomplete') };
          }

          if (+new Date(range.startDate) > +new Date(range.endDate)) {
            return {
              valid: false,
              errorMessage: t('date_range_picker_error_message_start_end_invalid'),
            };
          }

          if (+new Date(range.endDate) > +today) {
            return {
              valid: false,
              errorMessage: t('date_range_picker_error_message_future_date'),
            };
          }

          return { valid: true };
        }}
        relativeOptions={[]}
        rangeSelectorMode="absolute-only"
        showClearButton={false}
        dateOnly
      />
    </div>
  );
};

const TopsDownVolumeSelector = () => {
  const { t } = useTranslation();

  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { data: portfolioIds } = usePortfolioIds({ planType: batchMetadata?.costType ?? '' });

  const { triggerTopsDownVolumeDatasetImport } = useTriggerTopsDownVolumeDatasetImport();

  const { mutate: updateBatchMetadata } = useBatchMetadataMutation({
    meta: {
      inProgressNotificationText: t('api_in_progress_update_plan'),
      errorNotificationText: t('api_error_update_plan'),
      successNotificationText: t('api_success_update_plan'),
    },
    onSuccess: () => {
      if (batchMetadata?.batchId) {
        triggerTopsDownVolumeDatasetImport({ batchId: batchMetadata.batchId });
      }
    },
  });

  const options = useMemo(
    () => portfolioIds?.map((o) => ({ label: o, value: o })) || [],
    [portfolioIds],
  );

  const selectedOption = options.find(
    (item) => item.value === batchMetadata?.moduleSpecificMetadata?.portfolioId?.[0],
  )!;

  const onChange = (e: SelectChangeEvent) => {
    updateBatchMetadata({
      ...batchMetadata,
      moduleSpecificMetadata: {
        ...batchMetadata?.moduleSpecificMetadata,
        portfolioId: [e.detail.selectedOption.value as string],
      },
    });
  };

  return (
    <AdditionalSelect
      options={options}
      selectedOption={selectedOption}
      onChange={onChange}
      placeholder={t('portfolio_selector_placeholder')}
    />
  );
};

const TopsDownBaselineCpuSelector = () => {
  const { t } = useTranslation();

  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { data: scenarios } = useScenarios({ planType: batchMetadata?.costType ?? '' });

  const { triggerTopsDownBaselineCpuDatasetImport } = useTriggerTopsDownBaselineCpuDatasetImport();

  const { mutate: updateBatchMetadata } = useBatchMetadataMutation({
    meta: {
      inProgressNotificationText: t('api_in_progress_update_plan'),
      errorNotificationText: t('api_error_update_plan'),
      successNotificationText: t('api_success_update_plan'),
    },
    onSuccess: () => {
      if (batchMetadata?.batchId) {
        triggerTopsDownBaselineCpuDatasetImport({ batchId: batchMetadata.batchId });
      }
    },
  });

  const options = useMemo(() => scenarios?.map((o) => ({ label: o, value: o })) || [], [scenarios]);

  const selectedOption = options.find(
    (item) => item.value === batchMetadata?.moduleSpecificMetadata?.scenario?.[0],
  )!;

  const onChange = (e: SelectChangeEvent) => {
    updateBatchMetadata({
      ...batchMetadata,
      moduleSpecificMetadata: {
        ...batchMetadata?.moduleSpecificMetadata,
        scenario: [e.detail.selectedOption.value as string],
      },
    });
  };

  return (
    <AdditionalSelect
      options={options}
      selectedOption={selectedOption}
      onChange={onChange}
      placeholder={t('scenario_selector_placeholder')}
    />
  );
};

const OtrDateRangeImportSelector = ({
  currentDataset,
  disabled,
}: {
  currentDataset: SupportedDatasetType;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { triggerOtrObAutomatedDatasetImport } = useTriggerOtrObAutomatedDatasetImport();

  const { mutate: updateBatchMetadata } = useBatchMetadataMutation({
    meta: {
      inProgressNotificationText: t('api_in_progress_update_plan'),
      errorNotificationText: t('api_error_update_plan'),
      successNotificationText: t('api_success_update_plan'),
    },
  });

  const moduleSpecificMetadataKey = `${currentDataset}_dateRange`;

  const value = useMemo(() => {
    const string = batchMetadata?.moduleSpecificMetadata?.[moduleSpecificMetadataKey]?.[0];
    return string ? (JSON.parse(string) as DateRangePickerProps.AbsoluteValue) : null;
  }, [batchMetadata?.moduleSpecificMetadata, moduleSpecificMetadataKey]);

  const onChange = (e: DateRangePickerChangeEvent) => {
    updateBatchMetadata(
      {
        ...batchMetadata,
        moduleSpecificMetadata: {
          ...batchMetadata?.moduleSpecificMetadata,
          [moduleSpecificMetadataKey]: [JSON.stringify(e.detail.value)],
        },
      },
      {
        onSuccess: () => {
          if (batchMetadata?.batchId && e.detail.value?.type === 'absolute') {
            triggerOtrObAutomatedDatasetImport({
              batchId: batchMetadata.batchId,
              parameters: buildComputeParams({
                datasetName: currentDataset,
                startDate: e.detail.value.startDate,
                endDate: e.detail.value.endDate,
              }),
            });
          }
        },
      },
    );
  };

  return (
    <AdditionalDateRangePicker
      value={value}
      onChange={onChange}
      placeholder={t('import_date_range_placeholder')}
      disabled={disabled}
    />
  );
};

const DatasetSelector = ({
  currentDataset,
  onDatasetSelected,
  datasetOptions,
}: DatasetSelectorProps) => {
  const { t } = useTranslation();

  const selectedOption = datasetOptions.find((item) => item.value === currentDataset)!;

  return (
    <div style={{ width: 300 }}>
      <Select
        options={datasetOptions}
        selectedOption={selectedOption}
        onChange={onDatasetSelected}
        placeholder={t('select_generic_placeholder')}
      />
    </div>
  );
};

const ActionButton = ({ icon, text, onClick, disabled }: ActionButtonProps) => {
  const IconComponent = icon;
  const additionalStyle = disabled ? { color: 'grey', cursor: 'not-allowed' } : {};

  const Button = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      id={`button_${text}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '2.4rem',
        lineHeight: '12px',
        ...additionalStyle,
      }}
      onClick={!disabled ? onClick : undefined}
    >
      <IconComponent />
      <span style={{ fontSize: '9px', fontWeight: 'normal' }}>{text}</span>
    </div>
  );

  if (typeof disabled === 'string') {
    return (
      <Popover
        size="small"
        position="top"
        dismissButton={false}
        triggerType="custom"
        content={disabled}
      >
        {Button}
      </Popover>
    );
  }

  return Button;
};

const ActionButtonDivider = () => (
  <div style={{ height: '36px', width: '1px', backgroundColor: 'lightGrey' }} />
);

const HeaderActions = ({
  currentDataset,
  onDatasetSelected,
  datasetOptions,
  actionButtonGroups,
}: HeaderActionsProps) => (
  <div style={{ display: 'flex', gap: '30px' }}>
    {/* Selectors */}
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      {/* Dataset Selector */}
      <DatasetSelector
        currentDataset={currentDataset}
        onDatasetSelected={onDatasetSelected}
        datasetOptions={datasetOptions}
      />

      {/* Tops Down Forecasting - Volume Portfolio Selector */}
      {currentDataset === SupportedDatasetType.TOPS_DOWN_FORECAST_VOLUME ? (
        <TopsDownVolumeSelector />
      ) : null}

      {/* Tops Down Forecasting - CPU Scenario Selector */}
      {currentDataset === SupportedDatasetType.TOPS_DOWN_FORECAST_Q2G_CPU ? (
        <TopsDownBaselineCpuSelector />
      ) : null}

      {/* OTR - Date Range Import Selector */}
      {OTRAutomatedImportDatasetsWithDataRangeSelector.has(currentDataset) ||
      OTRAutomatedImportDatasetsWithTrailingSelector.has(currentDataset) ? (
        <OtrDateRangeImportSelector
          currentDataset={currentDataset}
          disabled={OTRAutomatedImportDatasetsWithTrailingSelector.has(currentDataset)}
        />
      ) : null}
    </div>

    {/* Button Groups */}
    <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
      {actionButtonGroups.map((group, index) => {
        const showingButtons = group.filter((o) => !o.hide);
        const isShowDivider = index < actionButtonGroups.length - 1 && showingButtons.length;

        return (
          <Fragment key={index}>
            {/* Buttons */}
            {showingButtons.map((button) => (
              <ActionButton {...button} key={button.text} />
            ))}
            {/* Divider */}
            {isShowDivider ? <ActionButtonDivider /> : null}
          </Fragment>
        );
      })}
    </div>
  </div>
);

const DatasetGridContainer = ({
  currentDataset,
  onDatasetSelected,
  datasetOptions,
  actionButtonGroups,
  headerText,
  description,
  children,
}: DataGridContainerProps) => {
  const { t } = useTranslation();

  return (
    <Container
      header={
        <Header
          actions={
            <HeaderActions
              currentDataset={currentDataset}
              onDatasetSelected={onDatasetSelected}
              datasetOptions={datasetOptions}
              actionButtonGroups={actionButtonGroups}
            />
          }
          description={description}
        >
          {headerText}
        </Header>
      }
    >
      {OTRAutomatedImportDatasetsWithTrailingSelector.has(currentDataset) && (
        <Box margin={{ bottom: 'l' }}>
          <Alert type="info" header={t('no_date_range_picker')} dismissible={false} />
        </Box>
      )}
      {children}
    </Container>
  );
};

export default DatasetGridContainer;
